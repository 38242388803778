export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_VALIDATION = 'LOGIN_VALIDATION';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';
export const PASSWORD_RESET_EMAIL_VALIDATION = 'PASSWORD_RESET_EMAIL_VALIDATION';
export const PASSWORD_RESET_EMAIL_FAILURE = 'PASSWORD_RESET_EMAIL_FAILURE';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_VALIDATION = 'PASSWORD_RESET_VALIDATION';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';