import React, { Component } from 'react'
import { connect } from 'react-redux'
import Edit from '../../../UI/components/Edit'
import Create from '../../../UI/components/Create'
import UserForm from '../components/UserForm'
import { getUser, saveUser, deleteUser, createUser, fetchActiveRoles } from '../actions'
import { getUsersErrors, getUsersFetching, getCurrentUser, getUserSaveSuccess, getUserDeleteSuccess, getRoles } from '../selectors'
import FormWrapper from '../../../UI/components/FormWrapper'
import { userHasPermission } from '../../Auth/selectors'

const fields = [
	'firstname', 
	'lastname', 
	'email', 
	'password', 
	'password_confirmation', 
	'profileimageurl',
	{
		name: 'roles',
		default: [],
		parseExisting: value => {
			let ids = [];
			value.map((role, i) => {
				ids.push(role.id)
			})
			return ids;
		}
	}]
const EditForm = FormWrapper(Edit, fields)
const CreateForm = FormWrapper(Create, fields)

class UserEditFormContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		if(this.props.showRoles)
			this.props.getRoles();
		const userID = this.props.match.params.id;
		if(userID !== undefined)
			this.props.getUser(userID);
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.match.params.id !== nextProps.match.params.id){
			if(nextProps.showRoles)
				nextProps.getRoles();
			if(nextProps.match.params.id !== undefined)
				nextProps.getUser(nextProps.match.params.id);
		}
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'name' || name ==='description') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	render() {
		return (
			<div>
				{
					this.props.match.params.id ?
					<EditForm
						validate={this.validate} 
						name="User"
						editValues={this.props.user}
						backLink="/dashboard/users"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						deleteSuccess={this.props.deleteSuccess}
						onSubmit={this.props.onSave}
						onDelete={this.props.onDelete}
						hideStatuses={true}
						roles={this.props.roles}
						showRoles={this.props.showRoles}
					>
						<UserForm />
					</EditForm>
					:
					<CreateForm 
						validate={this.validate} 
						name="User"
						editValues={this.props.user}
						backLink="/dashboard/users"
						editorLink="/dashboard/user/editor/"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						onSubmit={this.props.onCreate}
						hideStatuses={true}
						roles={this.props.roles}
						showRoles={this.props.showRoles}
					>
						<UserForm />
					</CreateForm>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getUsersFetching(state),
		apiErrors: getUsersErrors(state),
		user: getCurrentUser(state),
		saveSuccess: getUserSaveSuccess(state),
		deleteSuccess: getUserDeleteSuccess(state),
		roles: getRoles(state),
		showRoles: userHasPermission(state, 'role:view')
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSave: data => {
			dispatch(saveUser(props.match.params.id, data))
		},

		onCreate: data => {
			dispatch(createUser(data))
		},

		onDelete: data => {
			dispatch(deleteUser(props.match.params.id))
		},

		getUser: id => {
			dispatch(getUser(id))
		},

		getRoles: () => {
			dispatch(fetchActiveRoles())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditFormContainer)