import {
	GET_TAGS_REQUEST,
	GET_TAGS_SUCCESS,
	GET_TAGS_FAILURE,
	SET_TAGS_ORDER,
	SET_TAGS_PAGE,
	SET_TAGS_SEARCH,
	SAVE_TAG_REQUEST,
	SAVE_TAG_SUCCESS,
	SAVE_TAG_VALIDATION,
	SAVE_TAG_FAILURE,
	GET_TAG_REQUEST,
	GET_TAG_SUCCESS,
	GET_TAG_FAILURE,
	DELETE_TAG_REQUEST,
	DELETE_TAG_SUCCESS,
	DELETE_TAG_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function tags(state = {
	isFetching: false,
	errors: [],
	currentTag: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	tags: [],
	perPage: 10,
	page: 1,
	orderBy: 'name',
	orderDir: 'asc',
	totalPages: 1,
	search: '',
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_TAGS_REQUEST:
		case GET_TAG_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_TAGS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				tags: action.tags,
				totalPages: action.totalPages
			})
		case GET_TAGS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_TAGS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_TAGS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_TAGS_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_TAG_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_TAG_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_TAG_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentTag: action.tag
			})
		case SAVE_TAG_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_TAG_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentTag: action.tag
			})
		case GET_TAG_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_TAG_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_TAG_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_TAG_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				tags: [],
				currentTag: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}