import {
	LOGIN_REQUEST, 
	LOGIN_SUCCESS, 
	LOGIN_VALIDATION,
	LOGIN_FAILURE, 
	LOGOUT_SUCCESS,
	PASSWORD_RESET_EMAIL_REQUEST,
	PASSWORD_RESET_EMAIL_SUCCESS,
	PASSWORD_RESET_EMAIL_VALIDATION,
	PASSWORD_RESET_EMAIL_FAILURE,
	PASSWORD_RESET_REQUEST,
	PASSWORD_RESET_SUCCESS,
	PASSWORD_RESET_VALIDATION,
	PASSWORD_RESET_FAILURE,
} from './actionTypes'

import { LOCATION_CHANGE } from 'react-router-redux';

export function auth(state = {
	isFetching: false,
	isAuthenticated: localStorage.getItem('cltoken') ? true : false,
	errors: [],
	formError: "",
	user: localStorage.getItem('cluser') ? localStorage.getItem('cluser') : ""
}, action) {
	switch (action.type) {
		case LOGIN_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				isAuthenticated: false,
				user: action.creds
			})
		case LOGIN_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				isAuthenticated: true,
				errorMessage: '',
				user: action.user
			})
		case LOGIN_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				isAuthenticated: false,
				errors: action.errors
			})
		case LOGIN_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				isAuthenticated: false,
				formError: action.error
			})
		case LOGOUT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				isAuthenticated: false,
				user: ""
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				formError: ""
			})
		default:
			return state
	}
}

export function passwordResetEmail(state = {
	isFetching: false,
	errors: [],
	success: false
}, action) {
	switch (action.type) {
		case PASSWORD_RESET_EMAIL_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
		case PASSWORD_RESET_EMAIL_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				errorMessage: '',
				success: true
			})
		case PASSWORD_RESET_EMAIL_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors
			})
		case PASSWORD_RESET_EMAIL_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				success: false
			})
		default:
			return state
	}
}

export function passwordReset(state = {
	isFetching: false,
	errors: [],
	success: false
}, action) {
	switch (action.type) {
		case PASSWORD_RESET_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
		case PASSWORD_RESET_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				errorMessage: '',
				success: true
			})
		case PASSWORD_RESET_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors
			})
		case PASSWORD_RESET_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				success: false
			})
		default:
			return state
	}
}