import axios from 'axios'
import { logoutUser } from './modules/Auth/actions'

class API {
	constructor(dispatch, token) {
		this.dispatch = dispatch
		this.token = token
		if(token === undefined)
			this.token = localStorage.getItem('cltoken')
	}

	call(endpoint, method, newOptions, authenticated, headers) {
		// let defaultOptions = {};
		// if(authenticated){
		// 	defaultOptions = {
		// 		'headers': {
		// 		    'Authorization': 'Bearer ' + this.token
		// 		}
		// 	}
		// }

		// let options = Object.assign({}, defaultOptions, newOptions)
		// console.log(options)

		let config = {
			method: method,
			url: process.env.REACT_APP_API_URL + '/api/' + endpoint,
			data: newOptions,
			params: newOptions,
			headers: headers
		}
		if(authenticated){
			config['headers'] = { ...config['headers'], 'Authorization': 'Bearer ' + this.token }
		}

		return axios(config)
		.then(response => response)
		.catch(error => {
			console.log(error);
			if(authenticated){
				if(error.response) {
					if(error.response.status === 401){
						if(this.dispatch !== undefined) {
							this.dispatch(logoutUser());
						}
						throw 'Token Expired';
					}
				}
			}

			throw error;


			// var errorMessage = "Unknown Error";
			// if(error.response) {
			// 	console.log(error.response);
			// 	if(error.response.status === 401){
			// 		if(this.dispatch !== undefined)
			// 			this.dispatch(logoutUser());
			// 		throw 'Token Expired';
			// 	}
			// 	if(error.response.data.message)
			// 		throw error.response.data.message;
			// 	else
			// 		throw "Unknown Error";
			// } else if(error.message) {
			// 	throw error.message;
			// } else {
			// 	throw "Unknown Error";
			// }
		})
	}

	login(email, password) {
		let options = {
			'email': email,
			'password': password
		}
		return this.call('login', 'post', options);
	}

	sendPasswordResetEmail(data) {
		let options = data
		return this.call('password/email', 'post', options);
	}

	resetPassword(data) {
		let options = data
		return this.call('password/reset', 'post', options);
	}

	getAudits(data) {
		return this.call('audits', 'get', data, true);
	}

	// Tag Categories
	getTagCategory(id) {
		return this.call('tag_categories/' + id, 'get', {}, true);
	}

	getTagCategories(data) {
		return this.call('tag_categories', 'get', data, true);
	}

	getActiveTagCategories(data) {
		return this.call('tag_categories/active', 'get', data, true);
	}

	saveTagCategory(id, data) {
		return this.call('tag_categories/' + id, 'put', data, true);
	}

	createTagCategory(data) {
		return this.call('tag_categories', 'post', data, true);
	}

	deleteTagCategory(id) {
		return this.call('tag_categories/' + id, 'delete', {}, true);
	}

	// Users
	getUser(id) {
		return this.call('users/' + id, 'get', {}, true);
	}

	getUsers(data) {
		return this.call('users', 'get', data, true);
	}

	saveUser(id, data) {
		// return this.call('users/' + id, 'put', data, true, { 'Content-Type': 'application/x-www-form-urlencoded' });
		let formData = new FormData();

	    for(var key in data) {
	    	
	    	formData.append(key, data[key])
	    }
	    formData.append('_method', 'put')
	    const config = {
	        headers: {
	           
	            'Authorization': 'Bearer ' + this.token
	        }
	    }

	    return axios.post(process.env.REACT_APP_API_URL + '/api/users/' + id, formData, config)
	    		.then(response => response)
	    		.catch(error => {
	    				if(error.response) {
	    					if(error.response.status === 401){
	    						if(this.dispatch !== undefined) {
	    							this.dispatch(logoutUser());
	    							return false;
	    						}
	    					}
	    				}

	    			throw error;
	    		})
	}

	createUser(data) {
		return this.call('users', 'post', data, true);
	}

	deleteUser(id) {
		return this.call('users/' + id, 'delete', {}, true);
	}

	getActiveRoles(data) {
		return this.call('roles/active', 'get', data, true);
	}

	// Tags
	getTag(id) {
		return this.call('tags/' + id, 'get', {}, true);
	}

	getTags(data) {
		return this.call('tags', 'get', data, true);
	}

	getActiveTags(data) {
		return this.call('tags/active', 'get', data, true);
	}

	saveTag(id, data) {
		return this.call('tags/' + id, 'put', data, true);
	}

	createTag(data) {
		return this.call('tags', 'post', data, true);
	}

	deleteTag(id) {
		return this.call('tags/' + id, 'delete', {}, true);
	}

	// Resources
	getResource(id) {
		return this.call('resources/' + id, 'get', {}, true);
	}

	getResources(data) {
		return this.call('resources', 'get', data, true);
	}

	getActiveResources(data) {
		return this.call('resources/active', 'get', data, true);
	}

	saveResource(id, data) {
		return this.call('resources/' + id, 'put', data, true);
	}

	createResource(data) {
		return this.call('resources', 'post', data, true);
	}

	submitResource(data) {
		return this.call('resources/submit', 'post', data, true);
	}

	deleteResource(id) {
		return this.call('resources/' + id, 'delete', {}, true);
	}

	import(data) {
		let formData = new FormData();

	    for(var key in data) {
	    	formData.append(key, data[key])
	    }
	    const config = {
	        headers: {
	           
	            'Authorization': 'Bearer ' + this.token
	        }
	    }

	    return axios.post(process.env.REACT_APP_API_URL + '/api/import', formData, config)
	    		.then(response => response)
	    		.catch(error => {
	    				if(error.response) {
	    					if(error.response.status === 401){
	    						if(this.dispatch !== undefined) {
	    							this.dispatch(logoutUser());
	    							return false;
	    						}
	    					}
	    				}

	    			throw error;
	    		})
	}
}

export default API;