import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchResources, setResourcesOrder, setResourcesPage, deleteResource, saveResource } from '../actions'
import { getResources, getResourcesPage, getResourcesPerPage, getResourcesOrderBy, getResourcesOrderDir, getResourcesTotalPages, getResourcesFetching, getResourcesStatus, getResourceDeleteSuccess, getResourceSaveSuccess } from '../selectors'
import Listing from '../../../UI/components/Listing'
import Delete from '../../../UI/components/Delete'
import moment from 'moment'

class ResourceListingContainer extends Component {
	// componentWillMount() {
	// 	if(this.props.match.params.status)
	// 		this.props.setStatus([this.props.match.params.status]);
	// }

	// componentWillReceiveProps(nextProps) {
	// 	if(this.props.match.params.status !== nextProps.match.params.status) {
	// 		if(nextProps.match.params.status)
	// 			this.props.setStatus([nextProps.match.params.status]);
	// 		else
	// 			this.props.setStatus(['active', 'draft']);
	// 	}
	// }

	render() {
		let columns = [
			{
				id: 'name',
				display: 'Name',
				value: item => {
					return <Link to={"/dashboard/resource/editor/" + item.id }>{item.name}</Link>
				}
			},
			{
				id: 'created_at',
				display: 'Date Submitted',
				value: item => {
					return moment.utc(item.created_at.date).local().format('MMMM D, YYYY - hh:mm a')
				}
			},
			{
				id: 'author',
				display: 'Submitted By',
				value: item => {
					return item.agency_rep_name
				}
			},
			{
				id: 'actions',
				display: '',
				value: item => {
					return (
						<div className="submission-actions">
							<button className="list-action-button approve-button" onClick={() => this.props.saveStatus(item.id, {'status': 'active'})}>Approve</button>
							<button className="list-action-button decline-button" onClick={() => this.props.saveStatus(item.id, {'status': 'declined'})}>Decline</button>
							<Link className="list-action-button review-button" to={"/dashboard/resource/editor/" + item.id }>Review</Link>
						</div>
					)
				}
			}
		];
		return (
			<div className="listing-wrapper">
				<div className="tab-nav">
					<Link to="/dashboard/resources" className="tab">All Resources</Link>
					<Link to="/dashboard/submissions" className="tab is-active">Review Submitted Resources</Link>
				</div>
				<div className="main-content">
					<Listing 
						columns={columns}
						status={['submitted']}
						{...this.props}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state = {}) => {
    return {
    	items: getResources(state),
    	page: getResourcesPage(state),
    	perPage: getResourcesPerPage(state),
    	orderBy: getResourcesOrderBy(state),
    	orderDir: getResourcesOrderDir(state),
    	totalPages: getResourcesTotalPages(state),
    	isLoading: getResourcesFetching(state),
    	deleteSuccess: getResourceDeleteSuccess(state),
    	saveSuccess: getResourceSaveSuccess(state)
    }
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		loadItems: data => {
			dispatch(fetchResources(data))
		},

		setOrderBy: (orderBy, orderDir) => {
			dispatch(setResourcesOrder(orderBy, orderDir))
		},

		setPage: page => {
			dispatch(setResourcesPage(page))
		},

		saveStatus: (id, data) => {
			dispatch(saveResource(id, data))
		},

		delete: id => {
			dispatch(deleteResource(id))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(ResourceListingContainer)