import React, { Component } from 'react';
import Input from '../../../UI/components/Input'
import Textarea from '../../../UI/components/Textarea'
import MultiSelect from '../../../UI/components/MultiSelect'

class TagForm extends Component {
	render() {
		const { values, errors } = this.props

		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<fieldset>
						<p className="form-title">Tag <span>Please choose a tag name and optional description</span></p>
						<Input type="text" name="name" label="Tag Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.name} errors={errors.name} />
						<Textarea name="description" label="Tag Description" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.description} errors={errors.description} />
					</fieldset>
					<fieldset>
						<p className="form-title">Categories <span>if this tag has a master category, select them from the list below. If not leave this space empty.</span></p>
						<MultiSelect items={this.props.tagCategories} selected={values.categories} onChange={this.props.onInputChange} name="categories" />
					</fieldset>
				</form>
			</div>
		)
	}
}

export default TagForm;