import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchTags, setTagsOrder, setTagsPage, setTagsSearch, deleteTag } from '../actions'
import { getTags, getTagsPage, getTagsPerPage, getTagsOrderBy, getTagsOrderDir, getTagsTotalPages, getTagsFetching, getTagsStatus, getTagDeleteSuccess, getTagsSearch } from '../selectors'
import Listing from '../../../UI/components/Listing'
import Delete from '../../../UI/components/Delete'
import moment from 'moment'
import Input from '../../../UI/components/Input'

class TagListingContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ''
		}

		this.onSearchChange = this.onSearchChange.bind(this)
	}

	onSearchChange(name, value) {
		this.setState({
			search: value
		})
	}

	render() {
		let columns = [
			{
				id: 'name',
				display: 'Name',
				value: item => {
					return <Link to={"/dashboard/tag/editor/" + item.id }>{item.name}</Link>
				}
			},
			{
				id: 'description',
				display: 'Description'
			},
			{
				id: 'status',
				display: 'Status'
			},
			{
				id: 'created_at',
				display: 'Date',
				value: item => {
					return moment.utc(item.created_at.date).local().format('MMMM D, YYYY - hh:mm a')
				}
			},
			{
				id: 'actions',
				display: '',
				value: item => {
					return <Delete onDelete={() => this.props.delete(item.id)} />
				}
			}
		];
		return (
			<div className="listing-wrapper">
				<div className="tab-nav">
					<Link to="/dashboard/tags" className="tab is-active">All Tags</Link>
					<Link to="/dashboard/tag-categories" className="tab">Tag Categories</Link>
				</div>
				<div className="listing-actions">
					<Link className="button" to="/dashboard/tag/editor">Add New Tag</Link>
					<div className="search-list">
						<Input type="search" name="search" label="Search" onInputChange={this.onSearchChange} />
						<button className="button" onClick={() => this.props.setSearch(this.state.search)}>Search</button>
					</div>
				</div>
				<div className="main-content">
					<Listing 
						columns={columns}
						{...this.props}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state = {}) => {
    return {
    	items: getTags(state),
    	page: getTagsPage(state),
    	perPage: getTagsPerPage(state),
    	orderBy: getTagsOrderBy(state),
    	orderDir: getTagsOrderDir(state),
    	totalPages: getTagsTotalPages(state),
    	search: getTagsSearch(state),
    	isLoading: getTagsFetching(state),
    	status: getTagsStatus(state),
    	deleteSuccess: getTagDeleteSuccess(state)
    }
};

const mapDispatchToProps = dispatch => {
	return {
		loadItems: data => {
			dispatch(fetchTags(data))
		},

		setOrderBy: (orderBy, orderDir) => {
			dispatch(setTagsOrder(orderBy, orderDir))
		},

		setPage: page => {
			dispatch(setTagsPage(page))
		},

		setSearch: search => {
			dispatch(setTagsSearch(search))
		},

		delete: id => {
			dispatch(deleteTag(id))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(TagListingContainer)