import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/logo.png'
import loginIcon from '../../../assets/images/icons/login-username.png'
import passwordIcon from '../../../assets/images/icons/login-password.png'
import Input from '../../../UI/components/Input'
import Loader from '../../../UI/components/Loader'

class Login extends Component {
	componentDidMount() {
		console.log(this.props);
		// this.props.onInputChange('token', this.props.match.params.token)
	}
	render() {
		const { values, errors } = this.props

		return (
			<div className="login-container">
				<div className="login">
					<img src={logo} alt="Children's Link" />
					<div className="form-wrapper">
						<form className="login-form" onSubmit={this.props.onSubmit}>
							<div className="input-wrapper">
								<img src={loginIcon} alt="User Icon" />
								<Input type="text" name="email" label="Email" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.email} errors={errors.email} />
							</div>
							<div className="input-wrapper">
								<img src={passwordIcon} alt="Password Icon" />
								<Input type="password" name="password" label="Password" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.password} errors={errors.password} />
							</div>
							<button className="button" disabled={this.props.isDisabled}>
								{
									this.props.isLoading ?
									<Loader/>
									:
									<span>Login</span>
								}
							</button>
						</form>
						<div className="form-error">{this.props.formError}</div>
					</div>

					<nav className="login-nav menu">
						<Link to="/forgot-password">Lost your password?</Link>
						<Link to="#">Back to Children's Link</Link>
					</nav>
				</div>
				
			</div>
		)
	}
}

export default Login;