import {
	GET_USERS_REQUEST,
	GET_USERS_SUCCESS,
	GET_USERS_FAILURE,
	SET_USERS_ORDER,
	SET_USERS_PAGE,
	SET_USERS_SEARCH,
	GET_ROLES_REQUEST,
	GET_ROLES_SUCCESS,
	GET_ROLES_FAILURE,
	SAVE_USER_REQUEST,
	SAVE_USER_SUCCESS,
	SAVE_USER_VALIDATION,
	SAVE_USER_FAILURE,
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_USER_FAILURE,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function users(state = {
	isFetching: false,
	errors: [],
	currentUser: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	users: [],
	roles: [],
	perPage: 10,
	page: 1,
	orderBy: 'firstname',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_USERS_REQUEST:
		case GET_USER_REQUEST:
		case GET_ROLES_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_USERS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				users: action.users,
				totalPages: action.totalPages
			})
		case GET_ROLES_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				roles: action.roles
			})
		case GET_USERS_FAILURE:
		case GET_ROLES_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_USERS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_USERS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_USERS_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_USER_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_USER_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_USER_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentUser: action.user
			})
		case SAVE_USER_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_USER_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentUser: action.user
			})
		case GET_USER_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_USER_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_USER_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_USER_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				users: [],
				roles: [],
				currentUser: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}