import {
	SAVE_IMPORT_REQUEST,
	SAVE_IMPORT_SUCCESS,
	SAVE_IMPORT_VALIDATION,
	SAVE_IMPORT_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function importFile(state = {
	isFetching: false,
	errors: [],
	saveSuccess: false
}, action) {
	switch (action.type) {
		case SAVE_IMPORT_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case SAVE_IMPORT_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_IMPORT_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true
			})
		case SAVE_IMPORT_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		default:
			return state
	}
}