import React, { Component } from 'react';
import xIcon from '../../assets/images/icons/white/X.svg'
import xIconGray from '../../assets/images/icons/X.svg'
import checkIcon from '../../assets/images/icons/white/check.svg'

class Loader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			confirmDelete: false
		}

		this.onCancelClick = this.onCancelClick.bind(this)
	}

	onCancelClick() {
		this.setState({
			confirmDelete: !this.state.confirmDelete
		})
	}

	render() {
		return (
			<div className={"delete-item" + (this.state.confirmDelete ? ' confirm' : '')}>
				<span>Are you sure?</span>
				<div className="delete-actions">
					<button className="cancel-button" onClick={this.onCancelClick}>
						<img src={xIconGray} alt="Cancel Icon" className="gray-icon"/>
						<img src={xIcon} alt="Cancel Icon" className="white-icon" />
					</button>
					<button className="confirm-button" onClick={this.props.onDelete}><img src={checkIcon} alt="Confirm Icon" /></button>
				</div>
			</div>
		)
	}
}

export default Loader;