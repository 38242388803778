import React from 'react';

function MultiRowWrapper(Component, emptyRow) {
    class MultiRow extends Component {
        constructor(props) {
            super(props);

            this.onRowChange = this.onRowChange.bind(this);
            this.removeRow = this.removeRow.bind(this);
            this.addRow = this.addRow.bind(this);
        }

        onRowChange(index, name, value) {
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            if(updatedRows[index] !== undefined) {
                let currentRow = updatedRows[index];

                currentRow[name] = value;
            }
            this.props.onChange(this.props.name, updatedRows)
        }

        removeRow(index) {
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            updatedRows.splice(index, 1);
            this.props.onChange(this.props.name, updatedRows)
        }

        addRow(event) {
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            updatedRows.push(JSON.parse(JSON.stringify(emptyRow)));
            this.props.onChange(this.props.name, updatedRows)
        }

        render() {
            return <Component 
                        onRowChange={this.onRowChange}
                        addRow={this.addRow}
                        removeRow={this.removeRow}
                        {...this.props} />
        }
    }

    return MultiRow;
}

export default MultiRowWrapper;