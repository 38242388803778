import React, { Component } from 'react';
import Input from './Input'
import Loader from './Loader'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'

class Edit extends Component {
	constructor(props) {
		super(props);

		this.state = {
	    	toListing: false
	    }
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.deleteSuccess) {
			this.setState({
				toListing: true
			})
		}
	}

	getStatus() {
		let status = "Draft";
		if(this.props.editValues !== undefined && this.props.editValues.status !== undefined)
			status = this.props.editValues.status.charAt(0).toUpperCase() + this.props.editValues.status.slice(1);
		return status
	}

	render() {
		return (
			<div>
			{	
				this.state.toListing ?
				<Redirect to={this.props.backLink} />
				:
				<div className="model-form edit-form">
					<div className="form-heading">
						<Link to={this.props.backLink} className="back-link">Exit Editor</Link>
						{(this.props.editValues !== undefined && this.props.editValues.status == 'submitted') ? 
							<h1 className="editor-title">Agency Submitted Resource</h1>
							:
							<h1 className="editor-title">Edit {this.props.name}</h1>
						}
					</div>
					<div className="form-actions">
						<div class="status-draft-wrapper">
							{(!this.props.hideStatuses) 
								? 
									(this.props.editValues !== undefined && this.props.editValues.status == 'submitted') ?
										<span className="status">Submitted: {moment(this.props.editValues.created_at.date).format('YYYY/MM/DD [at] HH:mm')}</span> : <span className="status">Current Status: {this.getStatus()}</span>
								: ''
							}
							{(!this.props.hideStatuses) ? 
								<button className="button gray submit-draft" onClick={(event) => this.props.onSubmit(event, {'status': 'draft'})} disabled={this.props.isDisabled}>
									{
										this.props.isLoading ?
										<Loader/>
										:
										<span>Save As Draft</span>
									}
								</button> : ''}
						</div>
						{(this.props.editValues !== undefined && this.props.editValues.status == 'submitted') ? 
							<div className="submit-actions">
								<button className="button reject" onClick={(event) => this.props.onSubmit(event, {'status': 'active'})} disabled={this.props.isDisabled}>
									{
										this.props.isLoading ?
										<Loader/>
										:
										<span>Decline Submission</span>
									}
								</button>
								<button className="button approve" onClick={(event) => this.props.onSubmit(event, {'status': 'active'})} disabled={this.props.isDisabled}>
									{
										this.props.isLoading ?
										<Loader/>
										:
										<span>Approve Submission</span>
									}
								</button>
							</div>
						 :
						 	<div className="submit-actions">
								<button className="button submit" onClick={(event) => this.props.onSubmit(event, {'status': 'active'})} disabled={this.props.isDisabled}> 
									{
										this.props.isLoading ?
										<Loader/>
										:
										<span>Publish {this.props.name}</span>
									}
									
								</button>
							</div>
						}
					</div>
					{(this.props.saveSuccess) ?
						<div class="form-message">
							Save successful!
						</div> : ''}
					<div className="form-delete">
						<button className="delete" onClick={this.props.onDelete} >[Delete {this.props.name}]</button>
					</div>
					<div className="form-content">
						{(this.props.isLoading ? <Loader/> : '')}
						{React.cloneElement(this.props.children, {...this.props})}
					</div>
				</div>
			}
			</div>
		)
	}
}

export default Edit;