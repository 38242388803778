import {
	GET_AUDITS_REQUEST,
	GET_AUDITS_SUCCESS,
	GET_AUDITS_FAILURE,
	SET_AUDITS_ORDER,
	SET_AUDITS_PAGE,
	SET_AUDITS_SEARCH
} from './actionTypes'
import { LOCATION_CHANGE } from 'connected-react-router';

export function audits(state = {
	isFetching: false,
	audits: [],
	perPage: 10,
	page: 1,
	orderBy: 'created_at',
	orderDir: 'desc',
	totalPages: 1,
	search: ''
}, action) {
	switch (action.type) {
		case GET_AUDITS_REQUEST:
			return Object.assign({}, state, {
				isFetching: true
			})
		case GET_AUDITS_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				audits: action.audits,
				totalPages: action.totalPages
			})
		case GET_AUDITS_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_AUDITS_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_AUDITS_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_AUDITS_SEARCH:
			return Object.assign({}, state, {
				search: action.search
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				audits: [],
				page: 1,
				search: ''
			})
		default:
			return state
	}
}