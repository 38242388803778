export const getTagsErrors = (state) => state.tags.errors
export const getTagsFetching = (state) => state.tags.isFetching
export const getCurrentTag = (state) => state.tags.currentTag
export const getTagSaveSuccess = (state) => state.tags.saveSuccess
export const getTagDeleteSuccess = (state) => state.tags.deleteSuccess

export const getTags = (state) => state.tags.tags
export const getTagsPage = (state) => state.tags.page
export const getTagsPerPage = (state) => state.tags.perPage
export const getTagsOrderBy = (state) => state.tags.orderBy
export const getTagsOrderDir = (state) => state.tags.orderDir
export const getTagsTotalPages = (state) => state.tags.totalPages
export const getTagsStatus = (state) => state.tags.status
export const getTagsSearch = (state) => state.tags.search