import React, { Component } from 'react'
import logo from '../../../assets/images/logo.png'
import { Route, Link } from 'react-router-dom'
import LandingContainer from '../containers/LandingContainer'
import AuditListingContainer from '../../Audit/containers/AuditListingContainer'
import TagCategoryListingContainer from '../../TagCategory/containers/TagCategoryListingContainer'
import TagCategoryEditFormContainer from '../../TagCategory/containers/TagCategoryEditFormContainer'
import UserListingContainer from '../../User/containers/UserListingContainer'
import UserEditFormContainer from '../../User/containers/UserEditFormContainer'
import TagListingContainer from '../../Tag/containers/TagListingContainer'
import TagEditFormContainer from '../../Tag/containers/TagEditFormContainer'
import ResourceListingContainer from '../../Resource/containers/ResourceListingContainer'
import SubmissionListingContainer from '../../Resource/containers/SubmissionListingContainer'
import ResourceEditFormContainer from '../../Resource/containers/ResourceEditFormContainer'
import ImportContainer from '../../Import/containers/ImportContainer'

import homeIcon from '../../../assets/images/icons/blue/home.svg'
import supportsIcon from '../../../assets/images/icons/blue/supports.svg'
import tagsIcon from '../../../assets/images/icons/blue/tags.svg'
import userIcon from '../../../assets/images/icons/blue/user.svg'
import logoutIcon from '../../../assets/images/icons/blue/log-out.svg'
import bellIcon from '../../../assets/images/icons/blue/bell.svg'

class Dashboard extends Component {
	render() {
		return (
			<div className="dashboard">
				<div className="dashboard-header">
					<div className="dashboard-header-inner">
						<div className="dashboard-header-left">
							<div className="logo">
								<a href="/dashboard"><img src={logo} alt="Children's Link" /></a>
							</div>
							<nav className="dashboard-menu">
								<div className="menu-parent">
									<Link to="/dashboard"><img src={homeIcon} alt="Home Icon" /> Home</Link>
								</div>
								{this.props.showAudits ?
								<div className="menu-parent">
									<Link to="/dashboard/activity-log"><img src={bellIcon} alt="Activity Log Icon" /> Activity Log</Link>
								</div> : '' }
								{this.props.showTags ?
								<div className="menu-parent">
									<span><img src={tagsIcon} alt="Tags Icon" /> Tags</span>
									<div className="sub-menu">
										<Link to="/dashboard/tags">All Tags</Link>
										<Link to="/dashboard/tag/editor">Add New Tag</Link>
										{/*<Link to="/dashboard/tags/archived">Archived Tags</Link>*/}
										<Link to="/dashboard/tag-categories">Tag Categories</Link>
										<Link to="/dashboard/tag-category/editor">Add New Category</Link>
										{/*<Link to="/dashboard/tag-categories/archived">Archived Categories</Link>*/}
									</div>
								</div> : ''}
								{this.props.showResources ?
								<div className="menu-parent">
									<span><img src={supportsIcon} alt="Resources Icon" /> Resources</span>
									<div className="sub-menu">
										<Link to="/dashboard/resources">All Resources</Link>
										<Link to="/dashboard/resource/editor">Add New Resource</Link>
										{/*<Link to="/dashboard/resources/archived">Archived Resources</Link>*/}
										<Link to="/dashboard/submissions">Review Submitted Reports</Link>
									</div>
								</div> : '' }
								{this.props.showUsers ?
								<div className="menu-parent">
									<span><img src={userIcon} alt="User Icon" /> Users</span>
									<div className="sub-menu">
										<Link to="/dashboard/users">All Users</Link>
										<Link to="/dashboard/user/editor">Add New User</Link>
									</div>
								</div> : '' }
							</nav>
						</div>
						<div className="dashboard-header-right">
							<nav className="right-menu">
								<Link to={ "/dashboard/user/editor/" + this.props.user.id }>{this.props.user.firstname} {this.props.user.lastname}</Link>
								<button onClick={this.props.onLogoutClick}><span>Logout </span><img src={logoutIcon} alt="Logout Icon" /></button>
							</nav>
						</div>
					</div>
				</div>
				<div className="dashboard-main">
					<Route exact path="/dashboard" component={LandingContainer} />
					<Route path="/dashboard/activity-log" component={AuditListingContainer} />
					<Route path="/dashboard/tag-categories" component={TagCategoryListingContainer} />
					<Route path="/dashboard/tag-category/editor/:id?" component={TagCategoryEditFormContainer} />
					<Route path="/dashboard/users" component={UserListingContainer} />
					<Route path="/dashboard/user/editor/:id?" component={UserEditFormContainer} />
					<Route path="/dashboard/tags" component={TagListingContainer} />
					<Route path="/dashboard/tag/editor/:id?" component={TagEditFormContainer} />
					<Route path="/dashboard/resources" component={ResourceListingContainer} />
					<Route path="/dashboard/submissions" component={SubmissionListingContainer} />
					<Route path="/dashboard/resource/editor/:id?" component={ResourceEditFormContainer} />
					<Route path="/dashboard/import" component={ImportContainer} />
				</div>
				<div className="mobile-menu">
					<Link to="/dashboard"><img src={homeIcon} alt="Home Icon" /> Home</Link>
					<Link to="/dashboard/activity-log"><img src={bellIcon} alt="Activity Log Icon" /> Activity Log</Link>
					<Link to="/dashboard/tags"><img src={tagsIcon} alt="Tags Icon" /> Tags</Link>
					<Link to="/dashboard/resources"><img src={supportsIcon} alt="Resources Icon" /> Resources</Link>
					<Link to="/dashboard/users"><img src={userIcon} alt="User Icon" /> Users</Link>
				</div>
			</div>
		)
	}
}

export default Dashboard;
