import React, { Component } from 'react'
import { connect } from 'react-redux'
import Landing from '../components/Landing'
import { fetchAudits } from '../../Audit/actions'
import { getAuditsFetching, getAudits } from '../../Audit/selectors'
import { userHasPermission } from '../../Auth/selectors'


class LandingContainer extends Component {
	componentDidMount() {
		console.log('test');
		if(this.props.showAudits)
			this.props.loadAudits();
	}

	render() {
		return (
			<Landing 
				audits={this.props.audits} 
				isLoading={this.props.isLoading} 
				showAudits={this.props.showAudits}
				showResources={this.props.showResources}
				showTags={this.props.showTags}
				showUsers={this.props.showUsers}
			/>
		)
	}
}

const mapStateToProps = state => {

	return {
		isLoading: getAuditsFetching(state),
		audits: getAudits(state),
		showAudits: userHasPermission(state, 'audit:view'),
		showResources: userHasPermission(state, 'resource:view'),
		showTags: userHasPermission(state, 'tag:view'),
		showUsers: userHasPermission(state, 'user:view')
	}
}

const mapDispatchToProps = dispatch => {
	return {
		loadAudits: () => {
			dispatch(fetchAudits({
				perPage: 10,
				orderBy: 'created_at',
				orderDir: 'desc'
			}))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingContainer)