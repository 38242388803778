import React, { Component } from 'react';
import Input from '../../../UI/components/Input'
import Loader from '../../../UI/components/Loader'
import { Link, Redirect } from 'react-router-dom'

class AgencySubmit extends Component {
	componentDidUpdate() {
		var height = document.getElementsByTagName("html")[0].scrollHeight;
  		window.parent.postMessage(["setHeight", height], "*");
	}
		
	render() {
		return (
			<div>
				<div className="model-form agency-submit-form">
					<div className="form-actions">
						<button className="button submit" onClick={this.props.onSubmit}>Submit</button>
					</div>
					{(this.props.saveSuccess) ?
						<div class="form-message">
							Submission successful! Thank you for reaching out to Children’s Link. Every resource we can offer to families is invaluable to us, and we are grateful you want to be involved. We will review your information and get back to you as soon as possible.
						</div> : ''}
					<div className="form-content">
						{(this.props.isLoading ? <Loader/> : '')}
						{React.cloneElement(this.props.children, {...this.props})}
					</div>
				</div>
			}
			</div>
		)
	}
}

export default AgencySubmit;