export const GET_TAGS_REQUEST = 'GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';
export const SET_TAGS_ORDER = 'SET_TAGS_ORDER';
export const SET_TAGS_PAGE = 'SET_TAGS_PAGE';
export const SET_TAGS_SEARCH = 'SET_TAGS_SEARCH';

export const GET_TAG_REQUEST = 'GET_TAG_REQUEST';
export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS';
export const GET_TAG_FAILURE = 'GET_TAG_FAILURE';
export const SAVE_TAG_REQUEST = 'SAVE_TAG_REQUEST';
export const SAVE_TAG_SUCCESS = 'SAVE_TAG_SUCCESS';
export const SAVE_TAG_VALIDATION = 'SAVE_TAG_VALIDATION';
export const SAVE_TAG_FAILURE = 'SAVE_TAG_FAILURE';
export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';