import 'react-app-polyfill/ie11';
import 'core-js/fn/number/is-integer';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/string/repeat';
import 'core-js/fn/array/find';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
// import thunkMiddleware from 'redux-thunk'
// import childrensLinkApp from './reducers'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './configureStore'

// let createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore)

// let store = createStoreWithMiddleware(childrensLinkApp);

let store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>, 
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
