import React, { Component } from 'react';
import Checkbox from './Checkbox';

class PublicResource extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showAdditionalInformation: false
		}

		this.toggleShowAdditionalInformation = this.toggleShowAdditionalInformation.bind(this)
	}

	toggleShowAdditionalInformation() {
		this.setState({
			showAdditionalInformation: !this.state.showAdditionalInformation
		})
	}

	getTagsByCategory(categoryName) {
		let tags = [];
		this.props.resource.tags.map((tag, i) => {
			tag.categories.map((category, i) => {
				if(category.name === categoryName)
					tags.push(tag.name);
			})
		})
		return tags;
	}

	render() {
		let { resource } = this.props;
		
		let location = this.getTagsByCategory('Location').join(", ");

		return (
			<div className={"public-resource" + (this.props.selected ? ' is-active' : '') + (this.props.isSecond ? ' dark' : '')} onClick={() => this.props.onClick(resource.id)}>
				<div className="resource-header">	
					<h2 className="resource-title">{resource.name}</h2>
					<p className="resource-location">{location}</p>
				</div>
				<div className="resource-description">{resource.description}</div>
				<div className="resource-details-wrapper">
					<table className="resource-details">
						<tbody>
							{resource.address.trim() !== '' ?
							<tr>
								<td>Address</td>
								<td><a href={"https://maps.google.com/?q=" + resource.address}>{resource.address}</a></td>
							</tr> : null}
							{resource.eligibility.trim() !== '' ?
							<tr>
								<td>Eligibility</td>
								<td>{resource.eligibility}</td>
							</tr> : null}
							{resource.age.trim() !== '' ?
							<tr>
								<td>Age</td>
								<td>{resource.age}</td>
							</tr> : null}
							{resource.location !== '' ?
							<tr>
								<td>Service Location</td>
								<td>{resource.location}</td>
							</tr> : null}
							{resource.contact_details.map((detail, i) => {
								if(detail.detail.trim() !== ''){
									let cellContent = detail.detail
									if(detail.type === 'website'){
										let url = detail.detail;
										if(!/^https?:\/\//i.test(url)) {
											url = 'http://' + url;
										}
										cellContent = <a href={url} target="_blank">{detail.detail}</a>
									}
									if(detail.type === 'email')
										cellContent = <a href={"mailto:" + detail.detail}>{detail.detail}</a>
									if(detail.type === 'phone')
										cellContent = <a href={"tel:" + detail.detail.replace(/[^0-9]/g, '')}>{detail.detail}</a>
									return <tr key={i}><td>{detail.label}</td><td>{cellContent}</td></tr>
								}
							})}
						</tbody>
					</table>
				</div>
				<div className="resource-links">
					<div className="social-links">
						{resource.social_media.map((social, i) => {
							return <a key={i} href={social.url} target="_blank" className={social.type}></a>
						})}
					</div>
					{(resource.additional_information !== '') ? <button className={"more-info-button button" + (this.state.showAdditionalInformation ? ' is-active' : '')} onClick={this.toggleShowAdditionalInformation}>More Information</button> : ''}
				</div>
				<Checkbox 
					index={resource.id}
					text="Add to Print List" 
					isChecked={this.props.isPrintSelected} 
					onChecked={() => this.props.onPrintSelected(resource)} />
				{(resource.additional_information !== '') ? <div className={"more-info" + (this.state.showAdditionalInformation ? ' is-active' : '')}>{resource.additional_information}</div> : ''}
			</div>
		)
	}
}

export default PublicResource;