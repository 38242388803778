import React, { Component } from 'react';
import Input from '../../../UI/components/Input'
import ImageUpload from '../../../UI/components/ImageUpload'
import MultiSelect from '../../../UI/components/MultiSelect'

class UserForm extends Component {
	render() {
		const { values, errors } = this.props

		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<fieldset>
						<p className="form-title">User Profile</p>
						<ImageUpload name="profileimage" onInputChange={this.props.onInputChange} previewURL={values.profileimageurl} errors={errors.profileimage} />
						<div className="field-group">
							<Input type="text" name="firstname" label="First Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.firstname} errors={errors.firstname} />
							<Input type="text" name="lastname" label="Last Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.lastname} errors={errors.lastname} />
						</div>
						<Input type="text" name="email" label="Email Address" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.email} errors={errors.email} />
						<div className="field-group">
							<Input type="password" name="password" label="Password" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.password} errors={errors.password} />
							<Input type="password" name="password_confirmation" label="Confirm Password" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.password_confirmation} errors={errors.password_confirmation} />
						</div>
					</fieldset>
					{this.props.showRoles ?
						<fieldset>
							<p className="form-title">Roles</p>
							<MultiSelect items={this.props.roles} selected={values.roles} onChange={this.props.onInputChange} name="roles" />
						</fieldset>
						: ''
					}
				</form>
			</div>
		)
	}
}

export default UserForm;