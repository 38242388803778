import React, { Component } from 'react';
import Input from './Input'
import Loader from './Loader'
import { Link, Redirect } from 'react-router-dom'

class Create extends Component {
	constructor(props) {
		super(props);

		this.state = {
	    	toListing: false
	    }
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.deleteSuccess) {
			this.setState({
				toListing: true
			})
		}
	}

	getStatus() {
		let status = "Draft";
		if(this.props.editValues !== undefined && this.props.editValues.status !== undefined)
			status = this.props.editValues.status.charAt(0).toUpperCase() + this.props.editValues.status.slice(1);
		return status
	}

	render() {
		return (
			<div>
			{	
				this.props.saveSuccess ?
				<Redirect to={ this.props.editorLink + this.props.editValues.id }/>
				:
				<div className="model-form edit-form">
					<div className="form-heading">
						<Link to={this.props.backLink} className="back-link">Exit Editor</Link>
						<h1 className="editor-title">Add New {this.props.name}</h1>
					</div>
					<div className="form-actions">
						<button className="button gray submit-draft" onClick={(event) => this.props.onSubmit(event, {'status': 'draft'})} disabled={this.props.isDisabled}>
							{
								this.props.isLoading ?
								<Loader/>
								:
								<span>Save As Draft</span>
							}
						</button>
						<button className="button submit" onClick={(event) => this.props.onSubmit(event, {'status': 'active'})} disabled={this.props.isDisabled}> 
							{
								this.props.isLoading ?
								<Loader/>
								:
								<span>Publish {this.props.name}</span>
							}
							
						</button>
					</div>
					<div className="form-content">
						{(this.props.isLoading ? <Loader/> : '')}
						{React.cloneElement(this.props.children, {...this.props})}
					</div>
				</div>
			}
			</div>
		)
	}
}

export default Create;