import React, { Component } from 'react';
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/logo.png'
import emailIcon from '../../../assets/images/icons/email.png'
import Input from '../../../UI/components/Input'
import Loader from '../../../UI/components/Loader'

class PasswordResetEmail extends Component {
	render() {
		const { values, errors } = this.props
		return (
			<div className="login-container">
				<div className="login">
					<img src={logo} alt="Children's Link" />
					<div className="form-wrapper">
						<p>If you forgot your password, you can create a new one by providing your email address. An email with a password reset link will be sent to your email.</p>	
						{ 
							!this.props.success ? 
							<form className="login-form" onSubmit={this.props.onSubmit}>
								<div className="input-wrapper">
									<img src={emailIcon} alt="User Icon" />
									<Input type="text" name="email" label="Email" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.email} errors={errors.email} />
								</div>
								<button className="button" disabled={this.props.isDisabled}>
									{
										this.props.isLoading ?
										<Loader/>
										:
										<span>Reset Password</span>
									}
								</button>
							</form>
							:
							<p>Thanks for your submission.</p>
						}
					</div>

					<nav className="login-nav menu">
						<Link to="/login">Back to Login</Link>
						<Link to="#">Back to Children's Link</Link>
					</nav>
				</div>
				
			</div>
		)
	}
}

export default PasswordResetEmail;