export const GET_TAG_CATEGORIES_REQUEST = 'GET_TAG_CATEGORIES_REQUEST';
export const GET_TAG_CATEGORIES_SUCCESS = 'GET_TAG_CATEGORIES_SUCCESS';
export const GET_TAG_CATEGORIES_FAILURE = 'GET_TAG_CATEGORIES_FAILURE';
export const SET_TAG_CATEGORIES_ORDER = 'SET_TAG_CATEGORIES_ORDER';
export const SET_TAG_CATEGORIES_PAGE = 'SET_TAG_CATEGORIES_PAGE';
export const SET_TAG_CATEGORIES_SEARCH = 'SET_TAG_CATEGORIES_SEARCH';

export const GET_TAG_CATEGORY_REQUEST = 'GET_TAG_CATEGORY_REQUEST';
export const GET_TAG_CATEGORY_SUCCESS = 'GET_TAG_CATEGORY_SUCCESS';
export const GET_TAG_CATEGORY_FAILURE = 'GET_TAG_CATEGORY_FAILURE';
export const SAVE_TAG_CATEGORY_REQUEST = 'SAVE_TAG_CATEGORY_REQUEST';
export const SAVE_TAG_CATEGORY_SUCCESS = 'SAVE_TAG_CATEGORY_SUCCESS';
export const SAVE_TAG_CATEGORY_VALIDATION = 'SAVE_TAG_CATEGORY_VALIDATION';
export const SAVE_TAG_CATEGORY_FAILURE = 'SAVE_TAG_CATEGORY_FAILURE';
export const DELETE_TAG_CATEGORY_REQUEST = 'DELETE_TAG_CATEGORY_REQUEST';
export const DELETE_TAG_CATEGORY_SUCCESS = 'DELETE_TAG_CATEGORY_SUCCESS';
export const DELETE_TAG_CATEGORY_FAILURE = 'DELETE_TAG_CATEGORY_FAILURE';