import {
	GET_RESOURCES_REQUEST,
	GET_RESOURCES_SUCCESS,
	GET_RESOURCES_FAILURE,
	SET_RESOURCES_ORDER,
	SET_RESOURCES_PAGE,
	SET_RESOURCES_SEARCH,
	SAVE_RESOURCE_REQUEST,
	SAVE_RESOURCE_SUCCESS,
	SAVE_RESOURCE_VALIDATION,
	SAVE_RESOURCE_FAILURE,
	GET_RESOURCE_REQUEST,
	GET_RESOURCE_SUCCESS,
	GET_RESOURCE_FAILURE,
	DELETE_RESOURCE_REQUEST,
	DELETE_RESOURCE_SUCCESS,
	DELETE_RESOURCE_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

import configureStore, { history } from '../../configureStore'

export function resources(state = {
	isFetching: false,
	errors: [],
	currentResource: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	resources: [],
	perPage: 10,
	page: 1,
	orderBy: 'name',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_RESOURCES_REQUEST:
		case GET_RESOURCE_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_RESOURCES_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				resources: action.resources,
				totalPages: action.totalPages
			})
		case GET_RESOURCES_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_RESOURCES_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_RESOURCES_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_RESOURCES_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_RESOURCE_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_RESOURCE_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_RESOURCE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentResource: action.resource
			})
		case SAVE_RESOURCE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_RESOURCE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentResource: action.resource
			})
		case GET_RESOURCE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_RESOURCE_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_RESOURCE_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_RESOURCE_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		// case CLEAR_PRINTLIST_RESOURCES:
		// 	return Object.assign({}, state, {
		// 		printListResources: [ ]
		// 	})
		// case ADD_PRINTLIST_RESOURCE:
		// 	return Object.assign({}, state, {
		// 		printListResources: [ ...state.printListResources, action.resource ]
		// 	})
		case LOCATION_CHANGE:
			if(history.location.pathname != '/public-resources'){
				return Object.assign({}, state, {
					isFetching: false,
					errors: [],
					resources: [],
					currentResource: undefined,
					saveSuccess: false,
					deleteSuccess: false,
					page: 1,
					search: ''
				})
			} else {
				return state
			}
		default:
			return state
	}
}