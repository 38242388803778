import React, { Component } from 'react'
import { connect } from 'react-redux'
import PasswordResetEmail from '../components/PasswordResetEmail'
import { sendPasswordResetEmail } from '../actions'
import { getPasswordResetEmailErrors, getPasswordResetEmailFetching, getPasswordResetEmailSuccess } from '../selectors'
import FormWrapper from '../../../UI/components/FormWrapper'

const PasswordResetEmailForm = FormWrapper(PasswordResetEmail, ['email'])

class PasswordResetEmailContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	validate(name, label, value) {
		let errors = [];
		if(name === 'email') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}
		
		return errors;
	}



	render() {
		return (
			<PasswordResetEmailForm 
				validate={this.validate} 
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getPasswordResetEmailFetching(state),
		apiErrors: getPasswordResetEmailErrors(state),
		success: getPasswordResetEmailSuccess(state)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSubmit: email => {
			dispatch(sendPasswordResetEmail(email))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetEmailContainer)