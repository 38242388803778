import React, { Component } from 'react'
import { connect } from 'react-redux'
import Edit from '../../../UI/components/Edit'
import Create from '../../../UI/components/Create'
import TagCategoryForm from '../components/TagCategoryForm'
import { getTagCategory, saveTagCategory, deleteTagCategory, createTagCategory } from '../actions'
import { getTagCategoriesErrors, getTagCategoriesFetching, getCurrentTagCategory, getTagCategorySaveSuccess, getTagCategoryDeleteSuccess } from '../selectors'
import FormWrapper from '../../../UI/components/FormWrapper'

const EditForm = FormWrapper(Edit, ['name', 'description'])
const CreateForm = FormWrapper(Create, ['name', 'description'])

class TagCategoryEditFormContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		const tagCategoryID = this.props.match.params.id;
		if(tagCategoryID !== undefined)
			this.props.getTagCategory(tagCategoryID);
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.match.params.id !== nextProps.match.params.id){
			if(nextProps.match.params.id !== undefined)
				nextProps.getTagCategory(nextProps.match.params.id);
		}
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'name' || name ==='description') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	render() {
		return (
			<div>
				{
					this.props.match.params.id ?
					<EditForm
						validate={this.validate} 
						name="Category"
						editValues={this.props.tagCategory}
						backLink="/dashboard/tag-categories"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						deleteSuccess={this.props.deleteSuccess}
						onSubmit={this.props.onSave}
						onDelete={this.props.onDelete}
					>
						<TagCategoryForm />
					</EditForm>
					:
					<CreateForm 
						validate={this.validate} 
						name="Category"
						editValues={this.props.tagCategory}
						backLink="/dashboard/tag-categories"
						editorLink="/dashboard/tag-category/editor/"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						onSubmit={this.props.onCreate}
					>
						<TagCategoryForm />
					</CreateForm>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getTagCategoriesFetching(state),
		apiErrors: getTagCategoriesErrors(state),
		tagCategory: getCurrentTagCategory(state),
		saveSuccess: getTagCategorySaveSuccess(state),
		deleteSuccess: getTagCategoryDeleteSuccess(state)
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSave: data => {
			dispatch(saveTagCategory(props.match.params.id, data))
		},

		onCreate: data => {
			dispatch(createTagCategory(data))
		},

		onDelete: data => {
			dispatch(deleteTagCategory(props.match.params.id))
		},

		getTagCategory: id => {
			dispatch(getTagCategory(id))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TagCategoryEditFormContainer)