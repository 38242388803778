// import React, {Component} from 'react'
import { connect } from 'react-redux'
import Dashboard from '../components/Dashboard'
import { logoutUser } from '../../Auth/actions'
import { getLoggedInUser } from '../../Auth/selectors'
import { userHasPermission } from '../../Auth/selectors'

const mapStateToProps = (state = {}) => {
    // console.log(state.router.location);
    return {
    	user: getLoggedInUser(state),
    	showAudits: userHasPermission(state, 'audit:view'),
    	showResources: userHasPermission(state, 'resource:view'),
    	showTags: userHasPermission(state, 'tag:view'),
    	showUsers: userHasPermission(state, 'user:view')
    }
};

const mapDispatchToProps = dispatch => {
	return {
		onLogoutClick: creds => {
			dispatch(logoutUser(creds))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)