import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchTagCategories, setTagCategoriesOrder, setTagCategoriesPage, deleteTagCategory, setTagCategoriesSearch } from '../actions'
import { getTagCategories, getTagCategoriesPage, getTagCategoriesPerPage, getTagCategoriesOrderBy, getTagCategoriesOrderDir, getTagCategoriesTotalPages, getTagCategoriesFetching, getTagCategoriesStatus, getTagCategoryDeleteSuccess, getTagCategoriesSearch } from '../selectors'
import Listing from '../../../UI/components/Listing'
import Delete from '../../../UI/components/Delete'
import Input from '../../../UI/components/Input'
import moment from 'moment'

class TagCategoryListingContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ''
		}

		this.onSearchChange = this.onSearchChange.bind(this)
	}

	onSearchChange(name, value) {
		this.setState({
			search: value
		})
	}

	render() {
		let columns = [
			{
				id: 'name',
				display: 'Name',
				value: item => {
					return <Link to={"/dashboard/tag-category/editor/" + item.id }>{item.name}</Link>
				}
			},
			{
				id: 'description',
				display: 'Description'
			},
			{
				id: 'status',
				display: 'Status'
			},
			{
				id: 'actions',
				display: '',
				value: item => {
					return <Delete onDelete={() => this.props.delete(item.id)} />
				}
			}
		];
		return (
			<div className="listing-wrapper">
				<div className="tab-nav">
					<Link to="/dashboard/tags" className="tab">All Tags</Link>
					<Link to="/dashboard/tag-categories" className="tab is-active">Tag Categories</Link>
				</div>
				<div className="listing-actions">
					<Link className="button" to="/dashboard/tag-category/editor">Add New Category</Link>
					<div className="search-list">
						<Input type="search" name="search" label="Search" onInputChange={this.onSearchChange} />
						<button className="button" onClick={() => this.props.setSearch(this.state.search)}>Search</button>
					</div>
				</div>
				<div className="main-content">
					<Listing 
						columns={columns}
						{...this.props}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state = {}) => {
    return {
    	items: getTagCategories(state),
    	page: getTagCategoriesPage(state),
    	perPage: getTagCategoriesPerPage(state),
    	orderBy: getTagCategoriesOrderBy(state),
    	orderDir: getTagCategoriesOrderDir(state),
    	totalPages: getTagCategoriesTotalPages(state),
    	isLoading: getTagCategoriesFetching(state),
    	status: getTagCategoriesStatus(state),
    	deleteSuccess: getTagCategoryDeleteSuccess(state),
    	search: getTagCategoriesSearch(state)
    }
};

const mapDispatchToProps = dispatch => {
	return {
		loadItems: data => {
			dispatch(fetchTagCategories(data))
		},

		setOrderBy: (orderBy, orderDir) => {
			dispatch(setTagCategoriesOrder(orderBy, orderDir))
		},

		setPage: page => {
			dispatch(setTagCategoriesPage(page))
		},

		setSearch: search => {
			dispatch(setTagCategoriesSearch(search))
		},

		delete: id => {
			dispatch(deleteTagCategory(id))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(TagCategoryListingContainer)