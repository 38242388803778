import React, { Component } from 'react';
import Input from '../../../UI/components/Input'
import Select from '../../../UI/components/Select'

class ContactDetails extends Component {
	

	render() {
		return (
			<div className="details">
				
				{this.props.rows.map((contact, i) => {
					return <div className="field-group">
						<Select name="type" label="Contact Type" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={contact.type}>
							<option value=""></option>
							<option value="text">Text</option>
							<option value="email">Email</option>
							<option value="phone">Phone</option>
							<option value="website">Website</option>
						</Select>
						<Input type="text" name="label" label="Contact Label" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={contact.label} />
						<Input type="text" name="detail" label="Contact Information" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={contact.detail} />
						<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
					</div>
				})}
				<button type="button" className="add" onClick={this.props.addRow}>
					Add Additional Contact Information <span>+</span>
				</button>
			</div>
		)
	}
}

export default ContactDetails;