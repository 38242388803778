import React, {Component} from 'react'
import Pagination from './Pagination'
import Loader from './Loader'
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

class Listing extends Component {
	constructor(props) {
		super(props);

		this.nextPage = this.nextPage.bind(this);
		this.previousPage = this.previousPage.bind(this);
		this.setPage = this.setPage.bind(this);
	}

	componentWillMount() {
		this.loadItems(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if( nextProps.orderBy !== this.props.orderBy ||
			nextProps.orderDir !== this.props.orderDir ||
			nextProps.page !== this.props.page ||
			nextProps.deleteSuccess && !this.props.deleteSuccess ||
			nextProps.saveSuccess && !this.props.saveSuccess ||
			nextProps.search !== this.props.search ) {
				this.loadItems(nextProps);
		}
	}

	loadItems(props) {
		let queryOptions = {
			perPage: props.perPage,
			page: props.page,
			orderBy: props.orderBy,
			orderDir: props.orderDir,
			status: props.status,
			search: props.search
		}
		props.loadItems(queryOptions)
	}

	isPreviousDisabled() {
		return this.props.page === 1
	}

	previousPage() {
		this.props.setPage(this.props.page - 1);
		scroll.scrollToTop();
	}

	isNextDisabled() {
		return this.props.page === this.props.totalPages
	}

	nextPage() {
		this.props.setPage(this.props.page + 1);
		scroll.scrollToTop();
	}

	setPage(page) {
		this.props.setPage(page);
		scroll.scrollToTop();
	}

	setOrder(orderBy) {
		let orderDir = 'desc';
		if(this.props.orderBy === orderBy && this.props.orderDir === 'desc')
			orderDir = 'asc';

		this.props.setOrderBy(orderBy, orderDir);
	}

	render() {
		return (
			<div className={"listing" + (this.props.isLoading ? ' is-loading' : '')}>
				<div className="model-list">
					{(this.props.isLoading) ? <Loader/> : ''}
					<table>
						<thead>
							<tr>
								{this.props.columns.map((column, i) => {
									return <th onClick={() => this.setOrder(column.id)} key={column.id} className={(this.props.orderBy === column.id ? 'is-ordered ' + this.props.orderDir : '')}>{column.display}</th>
								})}
							</tr>
						</thead>
						<tbody>
							{this.props.items.map((item, key) => {

								return <tr key={key}>
											{this.props.columns.map((column, i) => { 
												let value = item[column.id];
												if(column.value !== undefined)
													value = column.value(item)
												return <td key={i}>{value}</td>
											})}
										</tr>
								}
							)}
							
						</tbody>
					</table>
				</div>
				<div className="page-navigation">
					<button onClick={this.previousPage} disabled={this.isPreviousDisabled()} className="button white">Previous Page</button>
					<Pagination currentPage={this.props.page} totalPages={this.props.totalPages} onPageClick={this.setPage} />
					<button onClick={this.nextPage} disabled={this.isNextDisabled()} className="button white">Next Page</button>
				</div>
			</div>
		)
	}
}


export default Listing