import React from "react";

class Checkbox extends React.Component {
    render() {
        let props = this.props;
        return (
            <div className="checkbox">
                <input type="checkbox" 
                    checked={this.props.isChecked} 
                    id={this.props.index}
                    onChange={(event) => {
                        event.stopPropagation();
                        props.onChecked();
                    }} />
                <label htmlFor={this.props.index} >{this.props.text}</label>
            </div>
        );
    }
}

export default Checkbox;