import React, { Component } from 'react';
import Field from './Field'

class Select extends Component {
	render() {
		// const { label, errors, removeFocus, ...rest} = this.props

		return (
			<Field {...this.props} type="select">
					<select>
						{this.props.children}
					</select>
				</Field>
		)
	}
}

export default Select;