import {
	SAVE_IMPORT_REQUEST,
	SAVE_IMPORT_SUCCESS,
	SAVE_IMPORT_VALIDATION,
	SAVE_IMPORT_FAILURE,
} from './actionTypes'

import { handleErrors } from '../../actions'
import API from '../../API'

function requestSaveImport() {
	return {
		type: SAVE_IMPORT_REQUEST,
		isFetching: true
	}
}

export function receiveSaveImport() {
	return {
		type: SAVE_IMPORT_SUCCESS,
		isFetching: false
	}
}

export function saveImportValidation(errors) {
	// console.log(errors);
	return {
		type: SAVE_IMPORT_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function saveImportError() {
	return {
		type: SAVE_IMPORT_FAILURE,
		isFetching: false
	}
}

export function sendImport(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveImport())
		let CLAPI = new API(dispatch);
		return CLAPI.import(data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveImport());
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveImportValidation(error.response.data.errors));
            	} else {
            		dispatch(saveImportError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}