import React, { Component } from 'react';
import Loader from '../../../UI/components/Loader'
import { Link, Redirect } from 'react-router-dom'

class Edit extends Component {
	constructor(props) {
		super(props);

		this.state = {
	    	filename: ""
	    }

	    this.handleChange = this.handleChange.bind(this)
	}

	handleChange(event) {
		event.preventDefault();

	    let reader = new FileReader();
	    let file = event.target.files[0];

	    reader.onloadend = () => {
	      this.setState({
	      	filename: file.name
	      });
	      this.props.onInputChange('file', file)
	    }

	    reader.readAsDataURL(file)
	}

	render() {
		return (
			<div>
			{	
				this.state.toListing ?
				<Redirect to={this.props.backLink} />
				:
				<div className="model-form edit-form">
					<div className="form-heading">
						<h1 className="editor-title">Import File</h1>
					</div>
					<div className="form-actions">
						<button className="button submit" onClick={this.props.onSubmit}>Import</button>
					</div>
					<div className="main-content">
						{(this.props.isLoading ? <Loader/> : '')}
						<form className="import-form">
							<div className="fake-upload-wrapper">
								<div className="fake-upload">
									<div className="filename">{(this.state.filename) ? this.state.filename : 'Add new import file' }</div>
									<button className="browse">Browse</button>
								</div>
								<input type="file" onChange={this.handleChange} />
							</div>
						</form>
					</div>
				</div>
			}
			</div>
		)
	}
}

export default Edit;