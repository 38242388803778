export const getUsersErrors = (state) => state.users.errors
export const getUsersFetching = (state) => state.users.isFetching
export const getCurrentUser = (state) => state.users.currentUser
export const getUserSaveSuccess = (state) => state.users.saveSuccess
export const getUserDeleteSuccess = (state) => state.users.deleteSuccess

export const getUsers = (state) => state.users.users
export const getUsersPage = (state) => state.users.page
export const getUsersPerPage = (state) => state.users.perPage
export const getUsersOrderBy = (state) => state.users.orderBy
export const getUsersOrderDir = (state) => state.users.orderDir
export const getUsersTotalPages = (state) => state.users.totalPages
export const getUsersStatus = (state) => state.users.status
export const getUsersSearch = (state) => state.users.search

export const getRoles = (state) => state.users.roles