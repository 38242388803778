import {
	GET_RESOURCE_REQUEST,
	GET_RESOURCE_SUCCESS,
	GET_RESOURCE_FAILURE,
	SAVE_RESOURCE_REQUEST,
	SAVE_RESOURCE_SUCCESS,
	SAVE_RESOURCE_VALIDATION,
	SAVE_RESOURCE_FAILURE,
	DELETE_RESOURCE_REQUEST,
	DELETE_RESOURCE_SUCCESS,
	DELETE_RESOURCE_FAILURE,
	GET_RESOURCES_REQUEST,
	GET_RESOURCES_SUCCESS,
	GET_RESOURCES_FAILURE,
	SET_RESOURCES_ORDER,
	SET_RESOURCES_PAGE,
	SET_RESOURCES_SEARCH
} from './actionTypes'

import { handleErrors } from '../../actions'
import API from '../../API'

function requestGetResource() {
	return {
		type: GET_RESOURCE_REQUEST,
		isFetching: true
	}
}

export function receiveGetResource(resource) {
	return {
		type: GET_RESOURCE_SUCCESS,
		isFetching: false,
		resource
	}
}

export function getResourceError() {
	return {
		type: GET_RESOURCE_FAILURE,
		isFetching: false
	}
}

// export function downloadResources(idList, i = 0) {
// 	return dispatch => {
// 		// Before ajax call
// 		dispatch(requestGetResource())
// 		let CLAPI = new API(dispatch);
// 		return CLAPI.getResource(idList[i])
// 			.then(response => {
// 				// console.log(response);
// 				// console.log(response.data);
// 				dispatch(receiveGetResource(response.data.data));

// 				if(i < idList.length)
// 					dispatch(downloadResources(idList, i + 1));
//                 // return response
//             })
//             .catch(error => {
//             	dispatch(getResourceError());
//             	dispatch(handleErrors(error));
//             });
// 	}
// }

export function getResource(id) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetResource())
		let CLAPI = new API(dispatch);
		return CLAPI.getResource(id)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetResource(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getResourceError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestSaveResource() {
	return {
		type: SAVE_RESOURCE_REQUEST,
		isFetching: true
	}
}

export function receiveSaveResource(resource) {
	return {
		type: SAVE_RESOURCE_SUCCESS,
		isFetching: false,
		resource
	}
}

export function saveResourceValidation(errors) {
	// console.log(errors);
	return {
		type: SAVE_RESOURCE_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function saveResourceError() {
	return {
		type: SAVE_RESOURCE_FAILURE,
		isFetching: false
	}
}

export function saveResource(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveResource())
		let CLAPI = new API(dispatch);
		return CLAPI.saveResource(id, data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveResource(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveResourceValidation(error.response.data.errors));
            	} else {
            		dispatch(saveResourceError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

export function createResource(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveResource())
		let CLAPI = new API(dispatch);
		return CLAPI.createResource(data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveResource(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveResourceValidation(error.response.data.errors));
            	} else {
            		dispatch(saveResourceError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

export function submitResource(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveResource())
		let CLAPI = new API(dispatch);
		return CLAPI.submitResource(data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveResource(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveResourceValidation(error.response.data.errors));
            	} else {
            		dispatch(saveResourceError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

function requestDeleteResource() {
	return {
		type: DELETE_RESOURCE_REQUEST,
		isFetching: true
	}
}

export function receiveDeleteResource() {
	return {
		type: DELETE_RESOURCE_SUCCESS,
		isFetching: false
	}
}

export function saveDeleteResourceError() {
	return {
		type: DELETE_RESOURCE_FAILURE,
		isFetching: false
	}
}

export function deleteResource(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestDeleteResource())
		let CLAPI = new API(dispatch);
		return CLAPI.deleteResource(id, data)
			.then(response => {
                dispatch(receiveDeleteResource());
            })
            .catch(error => {
            	dispatch(saveDeleteResourceError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestGetResources() {
	return {
		type: GET_RESOURCES_REQUEST,
		isFetching: true
	}
}

export function receiveGetResources(resources, totalPages) {
	return {
		type: GET_RESOURCES_SUCCESS,
		isFetching: false,
		resources,
		totalPages
	}
}

export function getResourcesError() {
	return {
		type: GET_RESOURCES_FAILURE,
		isFetching: false
	}
}

export function fetchResources(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetResources())
		let CLAPI = new API(dispatch);
		return CLAPI.getResources(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetResources(response.data.data, response.data.meta.last_page));
                // return response
            })
            .catch(error => {
            	dispatch(getResourcesError());
            	dispatch(handleErrors(error));
            });
	}
}

export function fetchActiveResources(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetResources())
		let CLAPI = new API(dispatch);
		return CLAPI.getActiveResources(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetResources(response.data.data, response.data.meta.last_page));
                // return response
            })
            .catch(error => {
            	dispatch(getResourcesError());
            	dispatch(handleErrors(error));
            });
	}
}

export function setResourcesOrder(orderBy, orderDir) {
	return {
		type: SET_RESOURCES_ORDER,
		orderBy,
		orderDir
	}
}

export function setResourcesPage(page) {
	return {
		type: SET_RESOURCES_PAGE,
		page
	}
}

export function setResourcesSearch(search) {
	return {
		type: SET_RESOURCES_SEARCH,
		search
	}
}