import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import thunkMiddleware from 'redux-thunk'

export const history = createBrowserHistory()
// const prevHistoryPush = history.push;
// let lastLocation = history.location;

// history.listen(location => {
//   lastLocation = location;
// });
// history.push = (pathname, state = {}) => {
//   console.log(pathname);
//   console.log(lastLocation.pathname + lastLocation.search + lastLocation.hash);
//   if (
//     lastLocation === null ||
//     pathname !==
//       lastLocation.pathname + lastLocation.search + lastLocation.hash ||
//     JSON.stringify(state) !== JSON.stringify(lastLocation.state)
//   ) {
//     prevHistoryPush(pathname, state);
//   }
// };

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunkMiddleware
      ),
    ),
  )

  return store
}