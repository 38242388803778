import React from 'react';
import { PropTypes } from 'prop-types'
import { Route, Redirect } from 'react-router-dom';

const Authenticated = ({ isAuthenticated, component, ...rest}) => (
	<Route {...rest} render={(props) => {
	    return isAuthenticated ?
	    (React.createElement(component, { ...props, isAuthenticated })) :
	    (<Redirect to="/login" />);
	  }} />
);

Authenticated.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.func,
};

export default Authenticated;