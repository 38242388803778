export const GET_RESOURCES_REQUEST = 'GET_RESOURCES_REQUEST';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAILURE = 'GET_RESOURCES_FAILURE';
export const SET_RESOURCES_ORDER = 'SET_RESOURCES_ORDER';
export const SET_RESOURCES_PAGE = 'SET_RESOURCES_PAGE';
export const SET_RESOURCES_SEARCH = 'SET_RESOURCES_SEARCH';

export const GET_RESOURCE_REQUEST = 'GET_RESOURCE_REQUEST';
export const GET_RESOURCE_SUCCESS = 'GET_RESOURCE_SUCCESS';
export const GET_RESOURCE_FAILURE = 'GET_RESOURCE_FAILURE';
export const SAVE_RESOURCE_REQUEST = 'SAVE_RESOURCE_REQUEST';
export const SAVE_RESOURCE_SUCCESS = 'SAVE_RESOURCE_SUCCESS';
export const SAVE_RESOURCE_VALIDATION = 'SAVE_RESOURCE_VALIDATION';
export const SAVE_RESOURCE_FAILURE = 'SAVE_RESOURCE_FAILURE';
export const DELETE_RESOURCE_REQUEST = 'DELETE_RESOURCE_REQUEST';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_FAILURE = 'DELETE_RESOURCE_FAILURE';