export const getResourcesErrors = (state) => state.resources.errors
export const getResourcesFetching = (state) => state.resources.isFetching
export const getCurrentResource = (state) => state.resources.currentResource
export const getResourceSaveSuccess = (state) => state.resources.saveSuccess
export const getResourceDeleteSuccess = (state) => state.resources.deleteSuccess

export const getResources = (state) => state.resources.resources
export const getResourcesPage = (state) => state.resources.page
export const getResourcesPerPage = (state) => state.resources.perPage
export const getResourcesOrderBy = (state) => state.resources.orderBy
export const getResourcesOrderDir = (state) => state.resources.orderDir
export const getResourcesTotalPages = (state) => state.resources.totalPages
export const getResourcesStatus = (state) => state.resources.status
export const getResourcesSearch = (state) => state.resources.search