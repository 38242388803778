import React from 'react';
import { PropTypes } from 'prop-types'
import { Route, Redirect } from 'react-router-dom';

const Public = ({ isAuthenticated, component, ...rest }) => (
  <Route {...rest} render={(props) => {
    // if (isFetching) return <div></div>;
    return !isAuthenticated ?
    (React.createElement(component, { ...props, isAuthenticated })) :
    (<Redirect to="/dashboard" />);
  }} />
);

Public.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.func,
};

export default Public;