import React, { Component } from 'react';

class Field extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLabel: true
		}

		// This checks if the input is empty, used for browser autofill
		setInterval(() => {
			if(this.textInput && this.textInput.value !== '') {
				this.setState({ showLabel: false })
			}
		}, 500)
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.value === "" && nextProps.value !== "") {
			this.setState({ showLabel: false })
		}
	}

	hasError() {
		if(!this.props.errors || this.props.errors.length === 0)
			return false;
		return true;
	}

	render() {
		const { label, errors, removeFocus, onInputChange, children, ...rest} = this.props
		let onFocus = this.handleFocus.bind(this) 
		let onBlur = this.handleFocusOut.bind(this)
		let onChange = this.handleInputChange.bind(this)

		return (
			<div className={ "input-container " + (this.hasError() ? 'has-errors ' : '') + (!this.state.showLabel ? 'is-active ' : '') + (this.props.type) + ' ' + this.props.className }>
				<div className="field-wrapper">
					<label>{label}</label>
					{React.cloneElement(children, {...rest, onFocus, onBlur, onChange})}
				</div>
				{ this.hasError() ? 
					<ul className="errors">{
						errors.map((error, key) => 
							<li key={key}>{error}</li>
						)
					}</ul>
					: ''
				}
			</div>
		)
	}

	handleFocus(event) {
		this.setState({ showLabel: false })
	}

	handleFocusOut(event) {
		if(event.target.value === '')
			this.setState({ showLabel: true })
		if(this.props.removeFocus !== undefined)
			this.props.removeFocus(event.target.name, this.props.label);
	}

	handleInputChange(event) {
		this.props.onInputChange(event.target.name, event.target.value)
	}
}

export default Field;