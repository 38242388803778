export const getTagCategoriesErrors = (state) => state.tagCategories.errors
export const getTagCategoriesFetching = (state) => state.tagCategories.isFetching
export const getCurrentTagCategory = (state) => state.tagCategories.currentTagCategory
export const getTagCategorySaveSuccess = (state) => state.tagCategories.saveSuccess
export const getTagCategoryDeleteSuccess = (state) => state.tagCategories.deleteSuccess

export const getTagCategories = (state) => state.tagCategories.tagCategories
export const getTagCategoriesPage = (state) => state.tagCategories.page
export const getTagCategoriesPerPage = (state) => state.tagCategories.perPage
export const getTagCategoriesOrderBy = (state) => state.tagCategories.orderBy
export const getTagCategoriesOrderDir = (state) => state.tagCategories.orderDir
export const getTagCategoriesTotalPages = (state) => state.tagCategories.totalPages
export const getTagCategoriesStatus = (state) => state.tagCategories.status
export const getTagCategoriesSearch = (state) => state.tagCategories.search