import React, {Component} from 'react'
import Pagination from '../../../UI/components/Pagination'
import Loader from '../../../UI/components/Loader'
import MultiSelect from '../../../UI/components/MultiSelect'
import TypeAhead from '../../../UI/components/TypeAhead'
import PublicResource from './PublicResource'
import Select from 'react-select';
import queryString from 'query-string';
import { saveAs } from 'file-saver';

class Listing extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tags: [],
			serviceTags: [],
			locationTags: [],
			tempSearch: '',
			tempTags: [],
			showKeywords: false,
			showAgeGroup: true,
			showLocations: false,
			showFilters: false,
			showPrintSettings: false,
			selectedResource: null,
			printList: []
		}

		this.nextPage = this.nextPage.bind(this);
		this.setPage = this.setPage.bind(this);
		this.previousPage = this.previousPage.bind(this);
		this.onTagsChange = this.onTagsChange.bind(this);
		this.onServiceTagsChange = this.onServiceTagsChange.bind(this);
		this.onLocationTagsChange = this.onLocationTagsChange.bind(this);
		this.deselectKeywords = this.deselectKeywords.bind(this);
		this.toggleShowKeywords = this.toggleShowKeywords.bind(this);
		this.toggleShowFilters = this.toggleShowFilters.bind(this);
		this.toggleShowAgeGroup = this.toggleShowAgeGroup.bind(this);
		this.toggleShowLocations = this.toggleShowLocations.bind(this);
		this.onSearch = this.onSearch.bind(this);
		this.onSearchKeywordChange = this.onSearchKeywordChange.bind(this);
		this.onSearchClick = this.onSearchClick.bind(this);
		this.toggleLocation = this.toggleLocation.bind(this);
		this.onResourceClick = this.onResourceClick.bind(this);
		this.onInputKeyDown = this.onInputKeyDown.bind(this);

		this.togglePrintSelection = this.togglePrintSelection.bind(this);
		this.addToPrintList = this.addToPrintList.bind(this);
		this.isInPrintList = this.isInPrintList.bind(this);
		this.toggleShowPrintSettings = this.toggleShowPrintSettings.bind(this);

		this.printPageFromSelected = this.printPageFromSelected.bind(this);
		this.savePageFromSelected = this.savePageFromSelected.bind(this);
		this.viewPageFromSelected = this.viewPageFromSelected.bind(this);
		this.generatePageFromSelected = this.generatePageFromSelected.bind(this);
		this.createPageFromSelected = this.createPageFromSelected.bind(this);
	}

	componentWillMount() {
		const values = queryString.parse(this.props.location.search);

		if("tags" in values || "page" in values) {
			let newState = {};
			if("tags" in values) {
				newState['serviceTags'] = values.tags.split(',').map(number => parseInt(number));
			}

			

			this.setState(
				newState
			, () => {
				if("page" in values) {
					// newState['page'] = parseInt(values.page)
					this.props.setPage(parseInt(values.page));
				} else {
					this.loadItems(this.props)
				}
			})

		} else {
			this.loadItems(this.props);
		}
	}

	componentWillReceiveProps(nextProps) {
		if( nextProps.orderBy !== this.props.orderBy ||
			nextProps.orderDir !== this.props.orderDir ||
			nextProps.page !== this.props.page ) {
				this.loadItems(nextProps);
		}

		// console.log("CURRENT TAGS:");
		// console.log(this.props.tags);
		// console.log("NEXT TAGS:");
		// console.log(nextProps.tags);

		// fix for tags disappearing on page change
		// TODO: find a proper solution instead of fixing the symptoms
		if(nextProps.tags.length < this.props.tags.length) {
			// let t: Array = nextProps.tags;
			while(nextProps.tags.length > 0) nextProps.tags.pop();
			this.props.tags.forEach((v, i) => {
				nextProps.tags.push(v);
			});
			// nextProps.tags = this.props.tags;
		}

		if(nextProps.location.search !== this.props.location.search) {
			const values = queryString.parse(nextProps.location.search);
			const oldValues = queryString.parse(this.props.location.search);
			if("page" in values) {
				this.props.setPage(parseInt(values.page));
			} else if("page" in oldValues) {
				this.props.setPage(1);
			}
		}
	}

	setStateField(field, value) {
		let copy = { ...this.state };
		copy[field] = value;
		this.setState(copy);
	}

	updateState(newState, callback) {
		let copy = { ...this.state };
		for (const key in newState) {
			if (newState.hasOwnProperty(key)) {
				copy[key] = newState[key];
			}
		}
		this.setState(copy, callback);
	}

	loadItems(props) {
		let queryOptions = {
			perPage: props.perPage,
			page: props.page,
			orderBy: props.orderBy,
			orderDir: props.orderDir,
			tags: this.state.tags,
			serviceTags: this.state.serviceTags,
			locationTags: this.state.locationTags,
			search: this.state.search
		}
		if(props.status !== undefined)
			queryOptions['status'] = props.status
		props.loadItems(queryOptions)
	}

	isPreviousDisabled() {
		return this.props.page === 1
	}

	setQueryString(name, value) {
		this.props.history.push({
			pathname: '/public-resources',
			search: '?page='+value
		})
	}

	previousPage() {
		// this.props.setPage(this.props.page - 1);
		// window.parent.postMessage(["pageChange"], "*");
		// window.scrollTo(0,0);
		this.setPage(this.props.page - 1);
	}

	isNextDisabled() {
		return this.props.page === this.props.totalPages
	}

	nextPage() {
		// this.props.setPage(this.props.page + 1);
		// window.parent.postMessage(["pageChange"], "*");
		// window.scrollTo(0,0);
		this.setPage(this.props.page + 1);

	}

	setPage(page) {
		// this.props.setPage(page);
		window.parent.postMessage(["pageChange", page], "*");
		this.setQueryString('page', page)
	}

	getTagsWithOutCategory() {
		let tags = [];
		this.props.tags.map((tag, i) => {
			if(tag.categories.length === 0)
				tags.push(tag);
		})
		return tags;
	}

	getTagsByCategory(categoryName) {
		let tags = [];
		this.props.tags.map((tag, i) => {
			tag.categories.map((category, i) => {
				if(category.name === categoryName)
					tags.push(tag);
			})
		})
		return tags;
	}

	onTagsChange(name, value) {
		this.updateState({
			tempTags: value
		})
	}

	onServiceTagsChange(name, value) {
		this.props.setPage(1);
		this.updateState({
			serviceTags: value
		}, () => {
			this.loadItems(this.props)
		})
	}

	onLocationTagsChange(name, value) {
		this.props.setPage(1);
		this.updateState({
			locationTags: value
		}, () => {
			this.loadItems(this.props)
		})
	}

	deselectKeywords() {
		this.updateState({
			tempTags: []
		})
	}

	toggleShowFilters() {
		this.updateState({
			showFilters: !this.state.showFilters
		})
	}

	toggleShowAgeGroup() {
		this.updateState({
			showAgeGroup: !this.state.showAgeGroup
		})
	}

	toggleShowKeywords() {
		this.updateState({
			showKeywords: !this.state.showKeywords
		})
	}

	toggleShowLocations() {
		this.updateState({
			showLocations: !this.state.showLocations
		})
	}

	togglePrintSelection(resource) {
		let copy = [ ...this.state.printList ];

		if(copy.includes(resource)) {
			copy = copy.filter(x => x.id != resource.id);
		}
		else copy.push(resource);

		this.updateState({
			printList: copy
		});
	}

	isInPrintList(id) {
		return this.state.printList.some(x => x.id == id);
	}

	addToPrintList(resource) {
		this.togglePrintSelection(resource);
	}

	printPageFromSelected() {
		let page = this.createPageFromSelected();
		page.print();
		page.close();
	}

	savePageFromSelected() {
		let txt = this.generatePageFromSelected();
		let blob = new Blob([ txt ], { type: "text/html" });
		saveAs(blob, "childrens-link-community-resources.html");
	}

	viewPageFromSelected() {
		this.createPageFromSelected();
	}

	createPageFromSelected() {
		let txt = this.generatePageFromSelected();
		let page = window.open();
		page.document.write(txt);
		page.document.close();
		page.focus();
		return page;
	}

	generatePageFromSelected() {
		let pl;
		if(this.state.printList.length == 0) {
			pl = this.props.items;
		} else pl = this.state.printList;

		let txt = "";
		txt += 
		`
		<h1>Children's Link Community Resources</h1>
		<title>Children's Link Community Resources</title>
		<meta charset='utf-8'>
		<style>
			body {
				margin: auto;
				border 1px solid black;
				max-width: 8.5in;
				padding: 0.5in;
			}

			.hide-in-print {
				display: none;
			}

			@media print  
			{
				body {
					max-width: initial;
					margin: initial;
					border: initial;
					padding: initial;
				}

				div {
					page-break-inside: avoid;
				}
			}

			h2, h3 {
				margin: 5px;
			}

			p {
				margin: 10px;
				margin-bottom: 0px;
			}

			#desc {
				margin-left: 25px;
			}
			
			div.resource {
				border-top: 1px solid black;
				margin-bottom: 20px;
			}
		</style>
		`;

		function item(prefix, it) {
			return it.trim() == "" ? "" : "<li><strong>" + prefix + ": </strong>" + it + "</li>";
		}

		for (let i = 0; i < pl.length; i++) {
			const resource = pl[i];
			txt += "<div class='resource'>";
			txt += "<h2>" + resource.name + "</h2>";
			txt += resource.address.trim() == "" ? "" : ("<h3>" + resource.address + "</h3>");
			txt += "<p id='desc'>" + resource.description + "</p>";
			txt += "<ul>";
			txt += item("Eligibility", resource.eligibility);
			txt += item("Age", resource.age);
			txt += item("Service Location", resource.location);

			if(resource.contact_details.length > 0) {
				txt += "<li><strong>Contact Details:</strong><ul>";
				resource.contact_details.map((v, i) => {
					let types = {
						website: "Website",
						email: "Email",
						phone: "Phone Number"
					};

					txt += item(types[v.type], v.detail);
				});
				txt += "</li></ul>";
			}
			
			txt += "</ul>";
			txt += "</div>";
		}

		return txt;
	}

	onSearch(value, { action }) {
		if (
	      action === "menu-close" ||
	      action === "input-blur" ||
	      action === "set-value"
	    ) {
	      return;
	    } else {
	      this.updateState({ tempSearch: value });
	    }
	}

	onSearchKeywordChange(value) {
		let newTags = [];
		value.forEach((tag) => {
			newTags.push(tag.value);
		})
		this.updateState({
			tempTags: newTags,
			tempSearch: "",
			search: ""
		})
	}

	onSearchClick() {
		this.props.setPage(1);
		this.updateState({
			tags: this.state.tempTags,
			search: this.state.tempSearch,
			showFilters: false
		}, () => {
			this.loadItems(this.props)
		})
	}

	onResourceClick(resourceID) {
		this.updateState({
			selectedResource: resourceID
		})
	}

	toggleLocation(itemID) {
		let itemIndex = this.state.locationTags.indexOf(itemID)
		let newSelected = this.state.locationTags.slice(0);

		if(itemIndex === -1){	
			newSelected.push(itemID)
			this.onLocationTagsChange('locationTags', newSelected);
		} else {
			newSelected.splice(itemIndex, 1)
			this.onLocationTagsChange('locationTags', newSelected);
		}
	}

	toggleShowPrintSettings() {
		this.updateState({
			showPrintSettings: !this.state.showPrintSettings
		});
	}

	componentDidUpdate() {
		var height = document.getElementsByTagName("html")[0].scrollHeight;
  		window.parent.postMessage(["setHeight", height], "*");
	}

	onInputKeyDown(event) {
		if (event.which == 13) {
            event.preventDefault();
            this.onSearchClick();
        }
	}

	render() {
		// console.log(this.props.tags);?\

		let serviceTags = this.getTagsByCategory('Service');
		let locationTags = this.getTagsByCategory('Location');
		let keywords = this.getTagsWithOutCategory();
		let searchBoxValues = [];
		this.state.tempTags.forEach((tag) => {
			keywords.forEach((keyword) => {
				if(keyword.id === tag)
					searchBoxValues.push({ value: keyword.id, label: keyword.name })
			})
		});

		const searchOptions = [];
		keywords.forEach((keyword) => {
			if(keyword.name.toLowerCase().startsWith(this.state.tempSearch.toLowerCase()))
				searchOptions.push({ value: keyword.id, label: keyword.name })
		})

		keywords.forEach((keyword) => {
			if(!searchOptions.some(e => e.value === keyword.id))
				searchOptions.push({ value: keyword.id, label: keyword.name })
		})

		let printUnsupported = (typeof window.print) == "undefined";

		return (
			<div className={"public-listing" + (this.props.isLoading ? ' is-loading' : '')}>
				<div className={(this.state.showFilters ? "show-filters " : '') + "filters-wrapper"}>
					<div className="filter-actions">
						<button className="back-to-top">Back to Top</button>
						<button className="toggle-filters" onClick={this.toggleShowFilters}>{(this.state.showFilters ? 'Hide' : 'Show')} Filters</button>
					</div>
					<div className="filters">
						<div className="location-filter">
							<div className="filter-dropdown">
								<p className="filter-title" onClick={this.toggleShowLocations}>Limit Location</p>
								{this.state.showLocations ? 
									<ul className="location-list">
										{locationTags.map((tag, i) => {
											return <li className={"location" + (this.state.locationTags.indexOf(tag.id) !== -1 ? ' is-active' : '')} onClick={() => this.toggleLocation(tag.id)}>{tag.name}</li>
										})}
									</ul>
									: ''
								}
							</div>
						</div>
						<div className="search-wrapper">
							<Select 
								className="search-field" 
								classNamePrefix="search-field"
								options={searchOptions} 
								value={searchBoxValues} 
								isMulti={true} 
								onChange={this.onSearchKeywordChange} 
								inputValue={this.state.tempSearch} 
								onInputChange={this.onSearch} 
								placeholder="Search" 
							/>
							<button className="button" onClick={this.onSearchClick}>Search</button>
						</div>
						<div className="filter-groups">
							<div className="filter-group age-group">
								<div className="filter-group-left">
									<button className={"collapse" + (this.state.showAgeGroup ? ' show' : '')} onClick={this.toggleShowAgeGroup}></button>
									<p className="filter-group-title">Select group</p>
								</div>
								<div className="filter-group-right">
									{this.state.showAgeGroup ? <MultiSelect items={serviceTags} selected={this.state.serviceTags} onChange={this.onServiceTagsChange} name="tags" className="medium" /> : ''}
								</div>
							</div>
							<div className="filter-group age-group">
								<div className="filter-group-left">
									<button className={"collapse" + (this.state.showKeywords ? ' show' : '')} onClick={this.toggleShowKeywords}></button>
									<p className="filter-group-title">Select keywords</p>
									<button className="uncheck" onClick={this.deselectKeywords}>Un-check All</button>
								</div>
								<div className="filter-group-right">
									{this.state.showKeywords ? <MultiSelect items={keywords} selected={this.state.tempTags} onChange={this.onTagsChange} name="tags" /> : ''}
									<div className="search-wrapper">
										<Select 
											className="search-field" 
											classNamePrefix="search-field"
											options={searchOptions} 
											value={searchBoxValues} 
											isMulti={true} 
											onChange={this.onSearchKeywordChange} 
											inputValue={this.state.tempSearch} 
											onInputChange={this.onSearch} 
											placeholder="Search" 
											onKeyDown={this.onInputKeyDown}
										/>
										<button className="button" onClick={this.onSearchClick}>Search</button>
									</div>
								</div>
							</div>
							<div className="filter-group age-group">
								<div className="filter-group-left">
									<button className={"collapse" + (this.state.showPrintSettings ? ' show' : '')} onClick={this.toggleShowPrintSettings}></button>
									<p className="filter-group-title">Show Print Settings</p>
								</div>
								<div className="filter-group-right">
									{!this.state.showPrintSettings ? '' : (
									<div>
										{printUnsupported? <strong>NOTE: Printing is not supported on your device/browser.</strong> : null}
										<div>
											<p>Will print the following:</p>
											<div id="to-print">
												{ this.state.printList.length == 0 ? <span className="print-item-name">The whole page</span> : (
													this.state.printList.map((v, i) => {
														return (
															<div key={v.id}>
																<span className="print-item-name"> {v.name} </span>
																<a className="x-button" href="javascript:void(0)" onClick={ () => { this.addToPrintList(v) }}> Remove </a>
															</div>
														);
													})
												)}
											</div>
										</div>
										<div id="print-button-container">
											<button className="button print-button" onClick={this.viewPageFromSelected}>Preview</button>
											<button className="button print-button" onClick={this.savePageFromSelected}>Save As...</button>
											<button className="button print-button" disabled={printUnsupported} onClick={this.printPageFromSelected}>Print</button>
										</div>
									</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="resource-list-wrapper">
					{(this.props.isLoading) ? <Loader/> : ''}
					<div className="resource-list">
						{this.props.items.map((resource, i) => {
							// let selected = false;
							// if(resource.id === this.state.selectedResource)
							// 	selected = true
							
							return <PublicResource 
								isSecond={i % 2 == 0} 
								key={resource.id} 
								resource={resource} 
								onClick={this.onResourceClick} 
								selected={(resource.id === this.state.selectedResource)}
								isPrintSelected={this.isInPrintList(resource.id)}
								onPrintSelected={this.addToPrintList} />
						})}
					</div>
				</div>
				<div className="page-navigation">
					<button onClick={this.previousPage} disabled={this.isPreviousDisabled()} className="button white">Previous Page</button>
					<Pagination currentPage={this.props.page} totalPages={this.props.totalPages} onPageClick={this.setPage} />
					<button onClick={this.nextPage} disabled={this.isNextDisabled()} className="button white">Next Page</button>
				</div>
			</div>
		)
	}
}


export default Listing