import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchResources, setResourcesOrder, setResourcesPage, deleteResource, saveResource, setResourcesSearch } from '../actions'
import { getResources, getResourcesPage, getResourcesPerPage, getResourcesOrderBy, getResourcesOrderDir, getResourcesTotalPages, getResourcesFetching, getResourcesStatus, getResourceDeleteSuccess, getResourcesSearch } from '../selectors'
import Listing from '../../../UI/components/Listing'
import Delete from '../../../UI/components/Delete'
import Input from '../../../UI/components/Input'
import moment from 'moment'

class ResourceListingContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ''
		}

		this.onSearchChange = this.onSearchChange.bind(this)
	}

	onSearchChange(name, value) {
		this.setState({
			search: value
		})
	}

	render() {
		let columns = [
			{
				id: 'name',
				display: 'Name',
				value: item => {
					return <Link to={"/dashboard/resource/editor/" + item.id }>{item.name}</Link>
				}
			},
			{
				id: 'author',
				display: 'Author',
				value: item => {
					if(item.author !== null)
						return item.author.firstname + " " + item.author.lastname
					return <i>Agency Submitted</i>;
				}
			},
			{
				id: 'status',
				display: 'Status'
			},
			{
				id: 'created_at',
				display: 'Date',
				value: item => {
					return moment.utc(item.created_at.date).local().format('MMMM D, YYYY - hh:mm a')
				}
			},
			{
				id: 'actions',
				display: '',
				value: item => {
					return <Delete onDelete={() => this.props.delete(item.id)} />
				}
			}
		];
		return (
			<div className="listing-wrapper">
				<div className="tab-nav">
					<Link to="/dashboard/resources" className="tab is-active">All Resources</Link>
					<Link to="/dashboard/submissions" className="tab">Review Submitted Resources</Link>
				</div>
				<div className="listing-actions">
					<Link className="button" to="/dashboard/resource/editor">Add New Resource</Link>
					<div className="search-list">
						<Input type="search" name="search" label="Search" onInputChange={this.onSearchChange} />
						<button className="button" onClick={() => this.props.setSearch(this.state.search)}>Search</button>
					</div>
				</div>
				<div className="main-content">
					<Listing 
						columns={columns}
						status={['active', 'draft']}
						{...this.props}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state = {}) => {
    return {
    	items: getResources(state),
    	page: getResourcesPage(state),
    	perPage: getResourcesPerPage(state),
    	orderBy: getResourcesOrderBy(state),
    	orderDir: getResourcesOrderDir(state),
    	totalPages: getResourcesTotalPages(state),
    	isLoading: getResourcesFetching(state),
    	deleteSuccess: getResourceDeleteSuccess(state),
    	search: getResourcesSearch(state)
    }
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		loadItems: data => {
			dispatch(fetchResources(data))
		},

		setOrderBy: (orderBy, orderDir) => {
			dispatch(setResourcesOrder(orderBy, orderDir))
		},

		setPage: page => {
			dispatch(setResourcesPage(page))
		},

		setSearch: search => {
			dispatch(setResourcesSearch(search))
		},

		delete: id => {
			dispatch(deleteResource(id))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(ResourceListingContainer)