import {
	CLEAR_ERROR,
	ADD_ERROR,
	GET_TAG_CATEGORIES_REQUEST,
	GET_TAG_CATEGORIES_SUCCESS,
	GET_TAG_CATEGORIES_FAILURE,
	SET_TAG_CATEGORIES_ORDER,
	SET_TAG_CATEGORIES_PAGE,
	SET_TAG_CATEGORIES_SEARCH,
	SAVE_TAG_CATEGORY_REQUEST,
	SAVE_TAG_CATEGORY_SUCCESS,
	SAVE_TAG_CATEGORY_VALIDATION,
	SAVE_TAG_CATEGORY_FAILURE,
	GET_TAG_CATEGORY_REQUEST,
	GET_TAG_CATEGORY_SUCCESS,
	GET_TAG_CATEGORY_FAILURE,
	DELETE_TAG_CATEGORY_REQUEST,
	DELETE_TAG_CATEGORY_SUCCESS,
	DELETE_TAG_CATEGORY_FAILURE
} from './actionTypes'
import { LOCATION_CHANGE } from 'react-router-redux';

export function tagCategories(state = {
	isFetching: false,
	errors: [],
	currentTagCategory: undefined,
	saveSuccess: false,
	deleteSuccess: false,
	tagCategories: [],
	perPage: 10,
	page: 1,
	orderBy: 'name',
	orderDir: 'asc',
	search: '',
	totalPages: 1,
	status: ['active','draft']
}, action) {
	switch (action.type) {
		case GET_TAG_CATEGORIES_REQUEST:
		case GET_TAG_CATEGORY_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				deleteSuccess: false
			})
		case GET_TAG_CATEGORIES_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				tagCategories: action.tagCategories,
				totalPages: action.totalPages
			})
		case GET_TAG_CATEGORIES_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case SET_TAG_CATEGORIES_ORDER:
			return Object.assign({}, state, {
				orderBy: action.orderBy,
				orderDir: action.orderDir
			})
		case SET_TAG_CATEGORIES_PAGE:
			return Object.assign({}, state, {
				page: action.page
			})
		case SET_TAG_CATEGORIES_SEARCH:
			return Object.assign({}, state, {
				page: 1,
				search: action.search
			})
		case SAVE_TAG_CATEGORY_REQUEST:
			return Object.assign({}, state, {
				isFetching: true,
				saveSuccess: false
			})
		case SAVE_TAG_CATEGORY_VALIDATION:
			return Object.assign({}, state, {
				isFetching: false,
				errors: action.errors,
				saveSuccess: false
			})
		case SAVE_TAG_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: true,
				currentTagCategory: action.tagCategory
			})
		case SAVE_TAG_CATEGORY_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				saveSuccess: false
			})
		case GET_TAG_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				currentTagCategory: action.tagCategory
			})
		case GET_TAG_CATEGORY_FAILURE:
			return Object.assign({}, state, {
				isFetching: false
			})
		case DELETE_TAG_CATEGORY_REQUEST:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case DELETE_TAG_CATEGORY_SUCCESS:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: true,
			})
		case DELETE_TAG_CATEGORY_FAILURE:
			return Object.assign({}, state, {
				isFetching: false,
				deleteSuccess: false
			})
		case LOCATION_CHANGE:
			return Object.assign({}, state, {
				isFetching: false,
				errors: [],
				tagCategories: [],
				currentTagCategory: undefined,
				saveSuccess: false,
				deleteSuccess: false,
				page: 1,
				search: ''
			})
		default:
			return state
	}
}