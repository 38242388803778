import React, { Component } from 'react'
import { connect } from 'react-redux'
import PasswordReset from '../components/PasswordReset'
import { resetPassword } from '../actions'
import { getPasswordResetErrors, getPasswordResetFetching, getPasswordResetSuccess } from '../selectors'
import FormWrapper from '../../../UI/components/FormWrapper'

const PasswordResetForm = FormWrapper(PasswordReset, ['email', 'password', 'password_confirmation', 'token'])

class PasswordResetContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	validate(name, label, value) {
		let errors = [];
		if(name === 'email' || name === 'password' || name === 'password_confirmation') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		if(name === 'password' || name === 'password_confirmation') {
			if(value.length < 6) {
				errors.push(label + " must be at least 6 characters.")
			}
		}

		return errors;
	}

	render() {
		return (
			<PasswordResetForm 
				validate={this.validate} 
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getPasswordResetFetching(state),
		apiErrors: getPasswordResetErrors(state),
		success: getPasswordResetSuccess(state)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSubmit: email => {
			dispatch(resetPassword(email))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer)