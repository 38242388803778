import { createSelector } from 'reselect'

export const getLoginErrors = (state) => state.auth.errors
export const getLoginFetching = (state) => state.auth.isFetching
export const getLoginFormError = (state) => state.auth.formError
export const getLoggedInUser = (state) => JSON.parse(state.auth.user)

export const getPasswordResetEmailErrors = (state) => state.passwordResetEmail.errors
export const getPasswordResetEmailFetching = (state) => state.passwordResetEmail.isFetching
export const getPasswordResetEmailSuccess = (state) => state.passwordResetEmail.success

export const getPasswordResetErrors = (state) => state.passwordReset.errors
export const getPasswordResetFetching = (state) => state.passwordReset.isFetching
export const getPasswordResetSuccess = (state) => state.passwordReset.success

export const userHasPermission = createSelector(
	[getLoggedInUser, (state, permission) => permission],
	(user, permission) => {
		if(user.roles !== undefined){
			if(user.roles.find(e => e.name === 'superadmin') || user.roles.find(e => e.name === 'admin')){
				return true;
			}
		}

		if(user.permissions !== undefined) {
			if(user.permissions.find(e => e.name === permission))
				return true;
		}

		return false;
	}
)