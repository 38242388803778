import React, { Component } from 'react'
import { connect } from 'react-redux'
import Import from '../components/Import'
import { sendImport } from '../actions'
import { getImportErrors, getImportSaveSuccess, getImportFetching } from '../selectors'
import FormWrapper from '../../../UI/components/FormWrapper'

const fields = [
	'file'
]
const ImportForm = FormWrapper(Import, fields)

class ImportContainer extends Component {
	// constructor(props) {
	//     super(props);

	// 	this.validate = this.validate.bind(this);
	// }

	// componentWillMount() {
	// 	this.props.getTagCategories();
	// 	const tagID = this.props.match.params.id;
	// 	if(tagID !== undefined)
	// 		this.props.getTag(tagID);
	// }

	// validate(name, label, value) {

	// 	let errors = [];
	// 	if(name === 'name' || name ==='description') {
	// 		if(value.length === 0) {
	// 			errors.push(label + " is required.");
	// 		}
	// 	}

	// 	return errors;
	// }

	render() {
		return (
			<div>
				<ImportForm apiErrors={this.props.apiErrors} saveSuccess={this.props.saveSuccess} onSubmit={this.props.onSave} />
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getImportFetching(state),
		apiErrors: getImportErrors(state),
		saveSuccess: getImportSaveSuccess(state)
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSave: data => {
			dispatch(sendImport(data))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportContainer)