import React, { Component } from 'react';

class ImageUpload extends Component {
	constructor(props) {
		super(props);

		this.state = {
			previewURL: this.props.previewURL || "",
			filename: ""
		}

		this.handleChange = this.handleChange.bind(this)
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.previewURL !== nextProps.previewURL) {
			this.setState({
				previewURL: nextProps.previewURL
			})
		}
	}

	handleChange(event) {
		event.preventDefault();

	    let reader = new FileReader();
	    let file = event.target.files[0];

	    reader.onloadend = () => {
	      this.setState({
	      	filename: file.name,
	        previewURL: reader.result
	      });
	      this.props.onInputChange(this.props.name, file)
	    }

	    reader.readAsDataURL(file)
	}

	render() {
		return (
			<div className="image-upload">
				<div className="preview-wrapper">
					<div className="preview" style={{backgroundImage: "url(" + this.state.previewURL + ")"}}></div>
				</div>
				<div className="fake-upload-wrapper">
					<div className="fake-upload">
						<div className="filename">{(this.state.filename) ? this.state.filename : 'Add new profile photo' }</div>
						<button className="browse">Browse</button>
					</div>
					<input type="file" onChange={this.handleChange} />
				</div>
			</div>
		)
	}
}

export default ImageUpload;