import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.scss';

import Authenticated from './Pages/Authenticated';
import Public from './Pages/Public';
import LoginContainer from './modules/Auth/containers/LoginContainer';
import DashboardContainer from './modules/Dashboard/containers/DashboardContainer';
import PasswordResetEmailContainer from './modules/Auth/containers/PasswordResetEmailContainer';
import PasswordResetContainer from './modules/Auth/containers/PasswordResetContainer';
import ErrorModal from './UI/components/ErrorModal';
import PublicResourceContainer from './modules/Resource/containers/PublicResourceContainer';
import AgencySubmissionFormContainer from './modules/Resource/containers/AgencySubmissionFormContainer';

import { clearError } from './actions';

import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './configureStore'

class App extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <div className="App">
          {(this.props.errorMessage ? <ErrorModal message={this.props.errorMessage} onClose={this.props.clearError} /> : '' )}
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login"/>}/>
            <Public path="/login" component={LoginContainer} isAuthenticated={this.props.isAuthenticated} />
            <Public path="/forgot-password" component={PasswordResetEmailContainer} isAuthenticated={this.props.isAuthenticated} />
            <Public path="/forgot-password" component={PasswordResetEmailContainer} isAuthenticated={this.props.isAuthenticated} />
            <Public path="/password-reset/:token" component={PasswordResetContainer} isAuthenticated={this.props.isAuthenticated} />
            <Authenticated path="/dashboard" component={DashboardContainer} isAuthenticated={this.props.isAuthenticated} />
            <Route path="/public-resources" component={PublicResourceContainer} />
            <Route path="/agency-submission" component={AgencySubmissionFormContainer} />
          </Switch>
        </div>
      </ConnectedRouter>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  errorMessage: PropTypes.string
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.errorMessage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearError: () => {
      dispatch(clearError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

