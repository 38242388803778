import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Loader from '../../../UI/components/Loader'

import supportsIcon from '../../../assets/images/icons/white/supports.svg'
import tagsIcon from '../../../assets/images/icons/white/tags.svg'
import userIcon from '../../../assets/images/icons/white/user.svg'

class Landing extends Component {
	render() {
		return (
			<div className="dashboard-landing">
				<nav className="button-nav">
					{this.props.showResources ? <Link to="dashboard/resources"><img src={supportsIcon} alt="Resources Icon" />View/Add Resources</Link> : '' }
					{this.props.showTags ? <Link to="dashboard/tags"><img src={tagsIcon} alt="Tags Icon" />View/Add Tag</Link> : '' }
					{this.props.showUsers ? <Link to="dashboard/users"><img src={userIcon} alt="Users Icon" />View/Add User</Link> : '' }
				</nav>
				{this.props.showAudits ? 
					<div className="main-content latest-entries">
						<h2 className="table-title">Latest Entries</h2>
						{ 
							this.props.isLoading ? 
							<Loader />
							:
							<div className="model-list">
								<table>
									<thead>
										<tr>
											<th>Title</th>
											<th>Author</th>
											<th>Date</th>
										</tr>
									</thead>
									<tbody>
										{this.props.audits.map((audit, key) => 
											<tr key={key}>
												<td>{audit.message}</td>
												{ (audit.user !== null ? <td>{audit.user.firstname} {audit.user.lastname}</td> : <td></td>)}
												<td>{moment.utc(audit.created_at.date).local().format('MMMM D, YYYY - hh:mm a')}</td>
											</tr>
										)}
										
									</tbody>
								</table>
							</div>
						}
					</div>
					: '' }
			</div>
		)
	}
}

export default Landing;
