import React, { Component } from 'react';
import Input from '../../../UI/components/Input'
import Select from '../../../UI/components/Select'

class SocialMedia extends Component {
	

	render() {
		return (
			<div className="details">
				
				{this.props.rows.map((social, i) => {
					return <div className="field-group">
						<Select name="type" label="Social Media Type" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={social.type}>
							<option value=""></option>
							<option value="facebook">Facebook</option>
							<option value="instagram">Instagram</option>
							<option value="twitter">Twitter</option>
						</Select>
						<Input type="text" name="url" label="URL Address" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={social.url} />
						<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
					</div>
				})}
				<button type="button" className="add" onClick={this.props.addRow}>
					Add Additional Social Media <span>+</span>
				</button>
			</div>
		)
	}
}

export default SocialMedia;