import React, { Component } from 'react';
import Input from '../../../UI/components/Input'
import Textarea from '../../../UI/components/Textarea'
import MultiSelect from '../../../UI/components/MultiSelect'
import MultiRowWrapper from './MultiRowWrapper'
import ContactDetails from './ContactDetails'
import SocialMedia from './SocialMedia'

import tagIcon from '../../../assets/images/icons/tags.svg'
import contactIcon from '../../../assets/images/icons/contact.svg'
import socialIcon from '../../../assets/images/icons/social-media.svg'
import informationIcon from '../../../assets/images/icons/information.svg'

const ContactDetailsField = MultiRowWrapper(ContactDetails, {
        type: '',
        label: '',
        detail: ''
    });

const SocialMediaField = MultiRowWrapper(SocialMedia, {
        type: '',
        url: ''
    });

class ResourceForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ''
		}

		this.onTagSearch = this.onTagSearch.bind(this)
	}

	onTagSearch(name, value) {
		this.setState({
			search: value
		})
	}

	getTagsWithOutCategory() {
		let tags = [];
		this.props.tags.map((tag, i) => {
			if(tag.categories.length === 0 && tag.name.toLowerCase().search(this.state.search.toLowerCase()) !== -1)
				tags.push(tag);
		})
		return tags;
	}

	getTagsByCategory(categoryName) {
		let tags = [];
		this.props.tags.map((tag, i) => {
			tag.categories.map((category, i) => {
				if(category.name === categoryName)
					tags.push(tag);
			})
		})
		return tags;
	}

	render() {
		const { values, errors } = this.props
		let serviceTags = this.getTagsByCategory('Service');
		let locationTags = this.getTagsByCategory('Location');
		let keywords = this.getTagsWithOutCategory();

		return (
			<div className="resource-form">
				<div className="main-content">
					<form>
						<p className="form-title">Agency Representative Contact Information</p>
						<Input type="text" name="agency_rep_name" label="Contact Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.agency_rep_name} errors={errors.agency_rep_name} />
						<div className="field-group">
							<Input type="text" name="agency_rep_email" label="Contact Email Address" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.agency_rep_email} errors={errors.agency_rep_email} />
							<Input type="text" name="agency_rep_phone" label="Contact Phone Number" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.agency_rep_phone} errors={errors.agency_rep_phone} />
						</div>
						<Textarea name="agency_rep_description" label="Contact Description" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.agency_rep_description} errors={errors.agency_rep_description} />
					</form>
				</div>
				<div className="main-content">
					<form>
						<fieldset>
							<p className="form-title">Resource Information</p>
							<Input type="text" name="name" label="Resource Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.name} errors={errors.name} />
							<Textarea name="description" label="Resource Description" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.description} errors={errors.description} maxlength="500" />
							<Input type="text" name="address" label="Address" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.address} errors={errors.address} />
							<Input type="text" name="location" label="Service Location" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.location} errors={errors.location} />
							<Input type="text" name="eligibility" label="Eligibility" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.eligibility} errors={errors.eligibility} />
							<Input type="text" name="age" label="Age" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.age} errors={errors.age} />
							<MultiSelect items={serviceTags} selected={values.tags} onChange={this.props.onInputChange} name="tags" className="large" />
						</fieldset>
						<fieldset>
							<p className="form-title">Location</p>
							<MultiSelect items={locationTags} selected={values.tags} onChange={this.props.onInputChange} name="tags" />
						</fieldset>
						<fieldset>
							<div className="icon-group">
								<img src={tagIcon} alt="Tags Icon" />
								<div>
									<p className="form-title">Tags</p>
									<Input type="search" name="search" label="Search for Tags" onInputChange={this.onTagSearch} />
								</div>
							</div>
							<MultiSelect items={keywords} selected={values.tags} onChange={this.props.onInputChange} name="tags" />
						</fieldset>
						<fieldset>
							<div className="icon-group">
								<img src={contactIcon} alt="Contact Icon" />
								<div>
									<p className="form-title">Contact Information</p>
									<ContactDetailsField name="contact_details" onChange={this.props.onInputChange} rows={values.contact_details} errors={errors.contact_details} />
								</div>
							</div>	
						</fieldset>
					</form>
				</div>
				<div className="main-content">
					<form>
						<fieldset>
							<div className="icon-group">
								<img src={socialIcon} alt="Social Media Icon" />
								<div>
									<p className="form-title">Social Media Information</p>
									<SocialMediaField name="social_media" onChange={this.props.onInputChange} rows={values.social_media} errors={errors.social_media} />
								</div>
							</div>	
						</fieldset>
					</form>
				</div>
				<div className="main-content">
					<form>
						<fieldset>
							<div className="icon-group">
								<img src={informationIcon} alt="Information Icon" />
								<div>
									<Textarea name="additional_information" label="Additional Information" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.additional_information} errors={errors.additional_information} />
								</div>
							</div>
						</fieldset>
					</form>
				</div>
			</div>
		)
	}
}

export default ResourceForm;