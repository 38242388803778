import {
	CLEAR_ERROR,
	ADD_ERROR
} from './actionTypes'

import API from './API'

export function clearError() {
	return {
		type: CLEAR_ERROR
	}
}

export function addError(error) {
	return {
		type: ADD_ERROR,
		error: error
	}
}

export function handleErrors(error) {
	return dispatch => {
		if(error !== 'Token Expired') {
			var errorMessage = "Unknown Error";
			if(error.response) {
				if(error.response.data.message)
					errorMessage = error.response.data.message;
				else
					errorMessage = "Unknown Error";
			} else if(error.message) {
				errorMessage = error.message;
			} else {
				errorMessage = "Unknown Error";
			}
			dispatch(addError(errorMessage));
		}
	}
}