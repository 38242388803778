import React, { Component } from 'react'
import { connect } from 'react-redux'
import AgencySubmit from '../components/AgencySubmit'
import ResourceForm from '../components/ResourceForm'
import { getResource, submitResource } from '../actions'
import { getResourcesErrors, getResourcesFetching, getCurrentResource, getResourceSaveSuccess, getResourceDeleteSuccess } from '../selectors'
import { getTags } from '../../Tag/selectors'
import { fetchActiveTags } from '../../Tag/actions'
import FormWrapper from '../../../UI/components/FormWrapper'

const fields = [
	'name', 
	'description',
	'address',
	'location',
	'eligibility',
	'age',
	'agency_rep_name',
	'agency_rep_email',
	'agency_rep_phone',
	'agency_rep_description',
	'additional_information',
	{
		name: 'tags',
		default: [],
		parseExisting: value => {
			let ids = [];
			value.map((tag, i) => {
				ids.push(tag.id)
			})
			return ids;
		}
	},
	{
		name: 'contact_details',
		default: [
			{
				type: '',
				label: '',
				detail: ''
			}
		]
	},
	{
		name: 'social_media',
		default: [
			{
				type: '',
				url: ''
			}
		]
	}
]
const AgencySubmitForm = FormWrapper(AgencySubmit, fields)

class ResourceEditFormContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		this.props.getTags();
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'name' || name ==='description') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	render() {
		return (
			<div>
				<AgencySubmitForm 
					validate={this.validate} 
					name="Resource"
					editValues={this.props.resource}
					isLoading={this.props.isLoading}
					apiErrors={this.props.apiErrors}
					saveSuccess={this.props.saveSuccess}
					onSubmit={this.props.onSubmit}
					tags={this.props.tags}
				>
					<ResourceForm />
				</AgencySubmitForm>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getResourcesFetching(state),
		apiErrors: getResourcesErrors(state),
		resource: getCurrentResource(state),
		saveSuccess: getResourceSaveSuccess(state),
		tags: getTags(state)
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSubmit: data => {
			dispatch(submitResource(data))
		},

		getResource: id => {
			dispatch(getResource(id))
		},

		getTags: () => {
			dispatch(fetchActiveTags())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceEditFormContainer)