import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchResources, setResourcesOrder, setResourcesPage, fetchActiveResources } from '../actions'
import { getResources, getResourcesPage, getResourcesPerPage, getResourcesOrderBy, getResourcesOrderDir, getResourcesTotalPages, getResourcesFetching } from '../selectors'
import { getTags } from '../../Tag/selectors'
import { fetchActiveTags } from '../../Tag/actions'
import moment from 'moment'
import PublicResourceListing from '../components/PublicResourceListing'

class PublicResourceContainer extends Component {
	componentWillMount() {
		this.props.getTags();
	}

	render() {
		return (
			<PublicResourceListing 
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state = {}) => {
    return {
    	items: getResources(state),
    	page: getResourcesPage(state),
    	perPage: getResourcesPerPage(state),
    	// orderBy: getResourcesOrderBy(state),
    	// orderDir: getResourcesOrderDir(state),
    	totalPages: getResourcesTotalPages(state),
    	isLoading: getResourcesFetching(state),
		tags: getTags(state)
    }
};

const mapDispatchToProps = dispatch => {
	return {
		loadItems: data => {
			dispatch(fetchActiveResources(data))
		},

		setOrderBy: (orderBy, orderDir) => {
			dispatch(setResourcesOrder(orderBy, orderDir))
		},

		setPage: page => {
			dispatch(setResourcesPage(page))
		},

		getTags: () => {
			dispatch(fetchActiveTags())
		},

		// getPrintListData: (printList) => {
		// 	dispatch(downloadResources(printList))
		// }
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(PublicResourceContainer)