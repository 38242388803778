import React, { Component } from 'react'
import { connect } from 'react-redux'
import Edit from '../../../UI/components/Edit'
import Create from '../../../UI/components/Create'
import TagForm from '../components/TagForm'
import { getTag, saveTag, deleteTag, createTag } from '../actions'
import { getTagsErrors, getTagsFetching, getCurrentTag, getTagSaveSuccess, getTagDeleteSuccess } from '../selectors'
import { getTagCategories } from '../../TagCategory/selectors'
import { fetchActiveTagCategories } from '../../TagCategory/actions'
import FormWrapper from '../../../UI/components/FormWrapper'

const fields = [
	'name', 
	'description',
	{
		name: 'categories',
		default: [],
		parseExisting: value => {
			let ids = [];
			value.map((category, i) => {
				ids.push(category.id)
			})
			return ids;
		}
	}
]
const EditForm = FormWrapper(Edit, fields)
const CreateForm = FormWrapper(Create, fields)

class TagEditFormContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		this.props.getTagCategories();
		const tagID = this.props.match.params.id;
		if(tagID !== undefined)
			this.props.getTag(tagID);
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.match.params.id !== nextProps.match.params.id){
			nextProps.getTagCategories();
			if(nextProps.match.params.id !== undefined)
				nextProps.getTag(nextProps.match.params.id);
		}
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'name' || name ==='description') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	render() {
		return (
			<div>
				{
					this.props.match.params.id ?
					<EditForm
						validate={this.validate} 
						name="Tag"
						editValues={this.props.tag}
						backLink="/dashboard/tags"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						deleteSuccess={this.props.deleteSuccess}
						onSubmit={this.props.onSave}
						onDelete={this.props.onDelete}
						tagCategories={this.props.tagCategories}
					>
						<TagForm />
					</EditForm>
					:
					<CreateForm 
						validate={this.validate} 
						name="Tag"
						editValues={this.props.tag}
						backLink="/dashboard/tags"
						editorLink="/dashboard/tag/editor/"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						onSubmit={this.props.onCreate}
						tagCategories={this.props.tagCategories}
					>
						<TagForm />
					</CreateForm>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getTagsFetching(state),
		apiErrors: getTagsErrors(state),
		tag: getCurrentTag(state),
		saveSuccess: getTagSaveSuccess(state),
		deleteSuccess: getTagDeleteSuccess(state),
		tagCategories: getTagCategories(state)
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSave: data => {
			dispatch(saveTag(props.match.params.id, data))
		},

		onCreate: data => {
			dispatch(createTag(data))
		},

		onDelete: data => {
			dispatch(deleteTag(props.match.params.id))
		},

		getTag: id => {
			dispatch(getTag(id))
		},

		getTagCategories: () => {
			dispatch(fetchActiveTagCategories())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TagEditFormContainer)