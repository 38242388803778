import React, { Component } from 'react'
import { connect } from 'react-redux'
import Edit from '../../../UI/components/Edit'
import Create from '../../../UI/components/Create'
import ResourceForm from '../components/ResourceForm'
import { getResource, saveResource, deleteResource, createResource } from '../actions'
import { getResourcesErrors, getResourcesFetching, getCurrentResource, getResourceSaveSuccess, getResourceDeleteSuccess } from '../selectors'
import { getTags } from '../../Tag/selectors'
import { fetchActiveTags } from '../../Tag/actions'
import FormWrapper from '../../../UI/components/FormWrapper'

const fields = [
	'name', 
	'description',
	'address',
	'location',
	'eligibility',
	'age',
	'agency_rep_name',
	'agency_rep_email',
	'agency_rep_phone',
	'agency_rep_description',
	'additional_information',
	{
		name: 'tags',
		default: [],
		parseExisting: value => {
			let ids = [];
			value.map((tag, i) => {
				ids.push(tag.id)
			})
			return ids;
		}
	},
	{
		name: 'contact_details',
		default: [
			{
				type: '',
				label: '',
				detail: ''
			}
		]
	},
	{
		name: 'social_media',
		default: [
			{
				type: '',
				url: ''
			}
		]
	}
]
const EditForm = FormWrapper(Edit, fields)
const CreateForm = FormWrapper(Create, fields)

class ResourceEditFormContainer extends Component {
	constructor(props) {
	    super(props);

		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		this.props.getTags();
		const resourceID = this.props.match.params.id;
		if(resourceID !== undefined)
			this.props.getResource(resourceID);
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.match.params.id !== nextProps.match.params.id){
			nextProps.getTags();
			if(nextProps.match.params.id !== undefined)
				nextProps.getResource(nextProps.match.params.id);
		}
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'name' || name ==='description') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	render() {
		return (
			<div>
				{
					this.props.match.params.id ?
					<EditForm
						validate={this.validate} 
						name="Resource"
						editValues={this.props.resource}
						backLink="/dashboard/resources"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						deleteSuccess={this.props.deleteSuccess}
						onSubmit={this.props.onSave}
						onDelete={this.props.onDelete}
						tags={this.props.tags}
					>
						<ResourceForm />
					</EditForm>
					:
					<CreateForm 
						validate={this.validate} 
						name="Resource"
						editValues={this.props.resource}
						backLink="/dashboard/resources"
						editorLink="/dashboard/resource/editor/"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						onSubmit={this.props.onCreate}
						tags={this.props.tags}
					>
						<ResourceForm />
					</CreateForm>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getResourcesFetching(state),
		apiErrors: getResourcesErrors(state),
		resource: getCurrentResource(state),
		saveSuccess: getResourceSaveSuccess(state),
		deleteSuccess: getResourceDeleteSuccess(state),
		tags: getTags(state)
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSave: data => {
			dispatch(saveResource(props.match.params.id, data))
		},

		onCreate: data => {
			dispatch(createResource(data))
		},

		onDelete: data => {
			dispatch(deleteResource(props.match.params.id))
		},

		getResource: id => {
			dispatch(getResource(id))
		},

		getTags: () => {
			dispatch(fetchActiveTags())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceEditFormContainer)