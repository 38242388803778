import {
	GET_TAG_REQUEST,
	GET_TAG_SUCCESS,
	GET_TAG_FAILURE,
	SAVE_TAG_REQUEST,
	SAVE_TAG_SUCCESS,
	SAVE_TAG_VALIDATION,
	SAVE_TAG_FAILURE,
	DELETE_TAG_REQUEST,
	DELETE_TAG_SUCCESS,
	DELETE_TAG_FAILURE,
	GET_TAGS_REQUEST,
	GET_TAGS_SUCCESS,
	GET_TAGS_FAILURE,
	SET_TAGS_ORDER,
	SET_TAGS_PAGE,
	SET_TAGS_SEARCH
} from './actionTypes'

import { handleErrors } from '../../actions'
import API from '../../API'

function requestGetTag() {
	return {
		type: GET_TAG_REQUEST,
		isFetching: true
	}
}

export function receiveGetTag(tag) {
	return {
		type: GET_TAG_SUCCESS,
		isFetching: false,
		tag
	}
}

export function getTagError() {
	return {
		type: GET_TAG_FAILURE,
		isFetching: false
	}
}

export function getTag(id) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetTag())
		let CLAPI = new API(dispatch);
		return CLAPI.getTag(id)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetTag(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getTagError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestSaveTag() {
	return {
		type: SAVE_TAG_REQUEST,
		isFetching: true
	}
}

export function receiveSaveTag(tag) {
	return {
		type: SAVE_TAG_SUCCESS,
		isFetching: false,
		tag
	}
}

export function saveTagValidation(errors) {
	// console.log(errors);
	return {
		type: SAVE_TAG_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function saveTagError() {
	return {
		type: SAVE_TAG_FAILURE,
		isFetching: false
	}
}

export function saveTag(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveTag())
		let CLAPI = new API(dispatch);
		return CLAPI.saveTag(id, data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveTag(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveTagValidation(error.response.data.errors));
            	} else {
            		dispatch(saveTagError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

export function createTag(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveTag())
		let CLAPI = new API(dispatch);
		return CLAPI.createTag(data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveTag(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveTagValidation(error.response.data.errors));
            	} else {
            		dispatch(saveTagError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

function requestDeleteTag() {
	return {
		type: DELETE_TAG_REQUEST,
		isFetching: true
	}
}

export function receiveDeleteTag() {
	return {
		type: DELETE_TAG_SUCCESS,
		isFetching: false
	}
}

export function saveDeleteTagError() {
	return {
		type: DELETE_TAG_FAILURE,
		isFetching: false
	}
}

export function deleteTag(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestDeleteTag())
		let CLAPI = new API(dispatch);
		return CLAPI.deleteTag(id, data)
			.then(response => {
                dispatch(receiveDeleteTag());
            })
            .catch(error => {
            	dispatch(saveDeleteTagError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestGetTags() {
	return {
		type: GET_TAGS_REQUEST,
		isFetching: true
	}
}

export function receiveGetTags(tags, totalPages) {
	return {
		type: GET_TAGS_SUCCESS,
		isFetching: false,
		tags,
		totalPages
	}
}

export function getTagsError() {
	return {
		type: GET_TAGS_FAILURE,
		isFetching: false
	}
}

export function fetchTags(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetTags())
		let CLAPI = new API(dispatch);
		return CLAPI.getTags(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetTags(response.data.data, response.data.meta.last_page));
                // return response
            })
            .catch(error => {
            	dispatch(getTagsError());
            	dispatch(handleErrors(error));
            });
	}
}

export function fetchActiveTags(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetTags())
		let CLAPI = new API(dispatch);
		return CLAPI.getActiveTags(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetTags(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getTagsError());
            	dispatch(handleErrors(error));
            });
	}
}

export function setTagsOrder(orderBy, orderDir) {
	return {
		type: SET_TAGS_ORDER,
		orderBy,
		orderDir
	}
}

export function setTagsPage(page) {
	return {
		type: SET_TAGS_PAGE,
		page
	}
}

export function setTagsSearch(search) {
	return {
		type: SET_TAGS_SEARCH,
		search
	}
}