import {
	GET_TAG_CATEGORY_REQUEST,
	GET_TAG_CATEGORY_SUCCESS,
	GET_TAG_CATEGORY_FAILURE,
	SAVE_TAG_CATEGORY_REQUEST,
	SAVE_TAG_CATEGORY_SUCCESS,
	SAVE_TAG_CATEGORY_VALIDATION,
	SAVE_TAG_CATEGORY_FAILURE,
	DELETE_TAG_CATEGORY_REQUEST,
	DELETE_TAG_CATEGORY_SUCCESS,
	DELETE_TAG_CATEGORY_FAILURE,
	GET_TAG_CATEGORIES_REQUEST,
	GET_TAG_CATEGORIES_SUCCESS,
	GET_TAG_CATEGORIES_FAILURE,
	SET_TAG_CATEGORIES_ORDER,
	SET_TAG_CATEGORIES_PAGE,
	SET_TAG_CATEGORIES_SEARCH
} from './actionTypes'

import { handleErrors } from '../../actions'
import API from '../../API'

function requestGetTagCategory() {
	return {
		type: GET_TAG_CATEGORY_REQUEST,
		isFetching: true
	}
}

export function receiveGetTagCategory(tagCategory) {
	return {
		type: GET_TAG_CATEGORY_SUCCESS,
		isFetching: false,
		tagCategory
	}
}

export function getTagCategoryError() {
	return {
		type: GET_TAG_CATEGORY_FAILURE,
		isFetching: false
	}
}

export function getTagCategory(id) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetTagCategory())
		let CLAPI = new API(dispatch);
		return CLAPI.getTagCategory(id)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetTagCategory(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getTagCategoryError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestSaveTagCategory() {
	return {
		type: SAVE_TAG_CATEGORY_REQUEST,
		isFetching: true
	}
}

export function receiveSaveTagCategory(tagCategory) {
	return {
		type: SAVE_TAG_CATEGORY_SUCCESS,
		isFetching: false,
		tagCategory
	}
}

export function saveTagCategoryValidation(errors) {
	// console.log(errors);
	return {
		type: SAVE_TAG_CATEGORY_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function saveTagCategoryError() {
	return {
		type: SAVE_TAG_CATEGORY_FAILURE,
		isFetching: false
	}
}

export function saveTagCategory(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveTagCategory())
		let CLAPI = new API(dispatch);
		return CLAPI.saveTagCategory(id, data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveTagCategory(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveTagCategoryValidation(error.response.data.errors));
            	} else {
            		dispatch(saveTagCategoryError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

export function createTagCategory(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveTagCategory())
		let CLAPI = new API(dispatch);
		return CLAPI.createTagCategory(data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveTagCategory(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveTagCategoryValidation(error.response.data.errors));
            	} else {
            		dispatch(saveTagCategoryError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

function requestDeleteTagCategory() {
	return {
		type: DELETE_TAG_CATEGORY_REQUEST,
		isFetching: true
	}
}

export function receiveDeleteTagCategory() {
	return {
		type: DELETE_TAG_CATEGORY_SUCCESS,
		isFetching: false
	}
}

export function saveDeleteCategoryError() {
	return {
		type: DELETE_TAG_CATEGORY_FAILURE,
		isFetching: false
	}
}

export function deleteTagCategory(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestDeleteTagCategory())
		let CLAPI = new API(dispatch);
		return CLAPI.deleteTagCategory(id, data)
			.then(response => {
                dispatch(receiveDeleteTagCategory());
            })
            .catch(error => {
            	dispatch(saveDeleteCategoryError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestGetTagCategories() {
	return {
		type: GET_TAG_CATEGORIES_REQUEST,
		isFetching: true
	}
}

export function receiveGetTagCategories(tagCategories, totalPages) {
	return {
		type: GET_TAG_CATEGORIES_SUCCESS,
		isFetching: false,
		tagCategories,
		totalPages
	}
}

export function getTagCategoriesError() {
	return {
		type: GET_TAG_CATEGORIES_FAILURE,
		isFetching: false
	}
}

export function fetchTagCategories(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetTagCategories())
		let CLAPI = new API(dispatch);
		return CLAPI.getTagCategories(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetTagCategories(response.data.data, response.data.meta.last_page));
                // return response
            })
            .catch(error => {
            	dispatch(getTagCategoriesError());
            	dispatch(handleErrors(error));
            });
	}
}

export function fetchActiveTagCategories(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetTagCategories())
		let CLAPI = new API(dispatch);
		return CLAPI.getActiveTagCategories(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetTagCategories(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getTagCategoriesError());
            	dispatch(handleErrors(error));
            });
	}
}

export function setTagCategoriesOrder(orderBy, orderDir) {
	return {
		type: SET_TAG_CATEGORIES_ORDER,
		orderBy,
		orderDir
	}
}

export function setTagCategoriesPage(page) {
	return {
		type: SET_TAG_CATEGORIES_PAGE,
		page
	}
}

export function setTagCategoriesSearch(search) {
	return {
		type: SET_TAG_CATEGORIES_SEARCH,
		search
	}
}