import React, { Component } from 'react';
import Input from '../../../UI/components/Input'
import Textarea from '../../../UI/components/Textarea'

class TagCategoryForm extends Component {
	render() {
		const { values, errors } = this.props

		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<Input type="text" name="name" label="Tag Name" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.name} errors={errors.name} />
					<Textarea name="description" label="Tag Description" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.description} errors={errors.description} />
				</form>
			</div>
		)
	}
}

export default TagCategoryForm;