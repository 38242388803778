import React, { Component } from 'react';
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/logo.png'
import Input from '../../../UI/components/Input'
import Loader from '../../../UI/components/Loader'

class PasswordReset extends Component {
	componentDidMount() {
		if(this.props.onInputChange)
			this.props.onInputChange('token', this.props.match.params.token)
	}

	render() {
		const { values, errors } = this.props
		return (
			<div className="login-container">
				<div className="login">
					{(this.props.isLoading ? <div className="loader-container"><div className="loader">Loading...</div></div> : '')}
					<img src={logo} alt="Children's Link" />
					<div className="form-wrapper">
						<h1 className="form-title">Create a new password</h1>
						<p>Please enter your new password to reset it.</p>	
						{ 
							!this.props.success ? 
							<form className="login-form" onSubmit={this.props.onSubmit}>
								<Input type="text" name="email" label="Email" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.email} errors={errors.email} />
								<Input type="password" name="password" label="New Password" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.password} errors={errors.password} />
								<Input type="password" name="password_confirmation" label="Confirm Password" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.password_confirmation} errors={errors.password_confirmation} />
								<button className="button" disabled={this.props.isDisabled}>
									{
										this.props.isLoading ?
										<Loader/>
										:
										<span>Confirm</span>
									}
								</button>
							</form>
							:
							<div>
								<p>Password reset successful.</p>
								<Link to="/dashboard/listing" className="button">Return to Login</Link>
							</div>
						}
					</div>

					<nav className="login-nav menu">
						<Link to="/login">Back to Login</Link>
						<Link to="#">Back to Children's Link</Link>
					</nav>
				</div>
				
			</div>
		)
	}
}

export default PasswordReset;