import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import {
	CLEAR_ERROR,
	ADD_ERROR
} from './actionTypes'
import { auth, passwordReset, passwordResetEmail } from './modules/Auth/reducers'
import { audits } from './modules/Audit/reducers'
import { tagCategories } from './modules/TagCategory/reducers'
import { users } from './modules/User/reducers'
import { tags } from './modules/Tag/reducers'
import { resources } from './modules/Resource/reducers'
import { importFile } from './modules/Import/reducers'

const errorMessage = (state = '', action) => {
	switch (action.type) {
		case CLEAR_ERROR:
			return ''
		case ADD_ERROR:
			return action.error;
		default:
			return state
	}
}

export default (history) => combineReducers({
	router: connectRouter(history),
	audits,
	auth,
	errorMessage,
	importFile,
	passwordReset,
	passwordResetEmail,
	resources,
	tagCategories,
	tags,
	users
})